.search-city {
  .list-cities {
    @include respond-to('tablet') {
      display: block !important;
    }
  }

  .dropdown-menu {
    &--nav-city-search {
      background: white;
      padding-bottom: 64px;
      @include respond-to('tablet') {
        padding-bottom: 110px;
      }
      @include respond-to('phone') {
        padding-bottom: 55px;
      }

      p {
        display: none;
      }
    }

    &--input {
      margin-top: 56px;
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      background: transparent;
      color: $grey;
      outline-offset: 0;
      border: 0 solid;
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;

      &::-ms-clear {
        display: none;
      }

      &:focus {
        color: $blue;
      }

      &::-webkit-input-placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: $grey !important;
      }

      &::placeholder {
        color: $grey;
      }

      @include respond-to('tablet_more') {
        margin-top: 72px;
        text-align: center;
        &::placeholder {
          text-align: center;
        }
      }
      @include respond-to('desktop') {
        font-size: 64px;
        margin-top: 65px;
        line-height: 80px;
        letter-spacing: 2.8px;
      }
      @include respond-to('tablet') {
        text-align: center;
        margin-top: 72px;
      }
    }
  }

  .tag {
    margin-bottom: 30px;
    @include respond-to('tablet_more') {
      margin-bottom: 0;
    }
    @include respond-to('desktop') {
      margin-bottom: 30px;
    }
    @include respond-to('phone') {
      margin-bottom: 24px;
    }

  }

  .list-cities {
    padding: 0 15px;
    max-width: 100%;
    background-color: rgba(238, 241, 247, 0.6);

    &.tags {
      padding: 0;
      background-color: $white;

      .tag {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        @include respond-to('desktop') {
          flex-wrap: nowrap;
        }

        a {
          text-decoration: underline;
          @include respond-to('tablet_more') {
            width: 40%;
          }
        }
      }
    }

    .box-city {
      max-width: 1140px;
      margin: 0 auto;
      padding-top: 39px;
      padding-bottom: 64px;
    }
  }

  p.nav-list-city--failure {
    padding: 80px 0 0 0;
    position: absolute;
    font-size: 16px;
    line-height: 48px;
    color: #b1b7c0;
    left: 0;
    width: 100%;
    background: #fff;
    text-align: center;
    margin-top: -31px;
  }

  .sticky-top {
    top: 64px;
    @include respond-to('phone') {
      position: relative;
      top: unset;
    }
    @include respond-to('hd') {
      top: 88px;
    }
  }
}

.box-city div + p.nav-list-city--failure {
  display: none;
}
