@mixin respond-to($media) {
  @if $media == phone {
    @media only screen and (max-width: $break-tablet) { @content; }
  }
  @else if $media == tablet {
    @media only screen and (min-width: $break-tablet + 1) and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == desktop {
    @media only screen and (min-width: $break-large) { @content; }
  }
  @else if $media == hd {
    @media only screen and (min-width: $break-hd) { @content; }
  }

  //special
  @else if $media == mobile {
    @media only screen and (max-width: $break-large - 1) { @content; }
  }
  @else if $media == phablet {
    @media only screen and (min-width: $break-small) { @content; }
  }
  @else if $media == netbook {
    @media only screen and (min-width: $break-netbook) { @content; }
  }
  @else if $media == tablet_more {
    @media only screen and (min-width: $break-tablet + 1) { @content; }
  }
  @else if $media == tabletandmobile {
    @media only screen and (min-width: 0) and (max-width: $break-large - 1) { @content; }
  }
}

