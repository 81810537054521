.city-summary {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  padding: 105px 16px 65px 16px;
  @include respond-to('phone') {
    max-width: 100%;
  }
  @include respond-to('tablet_more') {
    padding: 129px 24px 38px 24px;
    max-width: 100%;
  }
  @include respond-to('desktop') {
    padding: 139px 10px 124px 10px;
    flex-wrap: nowrap;
    max-width: 1140px;
  }
  @include respond-to('desktop') {
    padding-bottom: 131px;
  }

  .sticky-column-left {
    padding-bottom: 50px;
    @include respond-to('tablet_more') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 65px;
    }
  }

  .column-right {
    @include respond-to('hd') {
      padding-right: 80px;
    }
  }

  &__text-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.77px;
    color: #000;
    @include respond-to('tablet_more') {
      font-size: 48px;
      line-height: 56px;
    }
    @include respond-to('tablet') {
      br.not-tablet {
        display: none;
      }
    }
    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 70px;
      letter-spacing: -1.03px;
      + br {
        display: none;
      }
    }
  }

  &__text-right {
    font-size: 18px;
    line-height: 24px;
    color: #000;
    @include respond-to('desktop') {
      font-size: 22px;
      line-height: 32px;
    }

    b, strong {
      font-family: $ubuntu-font;
      font-weight: bold;
    }

    &--smaller {
      font-size: 14px;
      line-height: 24px;
      @include respond-to('desktop') {
        font-size: 16px;
      }
    }

    &--attention {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: $blue;
      margin-top: 38px;
      margin-bottom: 7px;
      @include respond-to('desktop') {
        font-size: 32px;
        line-height: 39px;
        margin-top: 28px;
      }
      @include respond-to('hd') {
        margin-bottom: 15px;
      }
    }

    &--extra-bold {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 32px;
      line-height: 32px;
      color: #000;
      margin-top: 43px;
      @include respond-to('tablet') {
        margin-top: 35px;
      }
      @include respond-to('desktop') {
        margin-top: 58px;
      }
      @include respond-to('hd') {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  span.blue {
    color: $blue;
  }
}

.is-demo {
  .city-summary {
    padding-top: 0;
    padding-bottom: 25px;

    .sticky-column-left {
      display: none;
      @include respond-to('desktop') {
        display: block;
      }
    }

    .demo-invitation {
      margin-bottom: 50px;
      @include respond-to('desktop') {
        margin-bottom: 95px;
        font-size: 22px;
        line-height: 32px;
      }

      .big {
        font-family: $montserrat-font;
        font-size: 24px;
        font-weight: bold;
        margin: 25px 0 50px;
        @include respond-to('desktop') {
          font-size: 32px;
          line-height: 40px;
          margin: 35px 0 75px;
        }
      }
    }

    .describe {
      font-size: 14px;
      color: $grey;

      a {
        font-size: 14px;
        text-decoration: underline;
      }
    }
  }
}
