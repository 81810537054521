.report {
  @import 'illustrations';
  @import 'intro';
  @import 'stats';
  @import 'numbered-section';
  @import 'form';

  .grey-spacer {
    margin: 45px 0 65px;
  }
}
