.city-citizens {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 0 16px;
  @include respond-to('phone') {
    padding-top: 24px;
    margin-top: -64px;
  }

  .slider-container {
    display: block;

    .slick-arrow {
      @include respond-to('phone') {
        display: none !important;
      }
    }
  }

  .sticky-column-left {
    padding-bottom: 50px;
    @include respond-to('tablet') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
    @include respond-to('hd') {
      padding-bottom: 130px;
      max-width: calc(1140px * 0.43);
      margin-left: calc(50% - (1140px / 2));
    }
  }

  .column-right {
    @include respond-to('tablet_more') {
      position: relative;
    }
    @include respond-to('desktop') {
      top: 79px;
      margin-bottom: 84px;
    }
    @include respond-to('hd') {
      width: calc(1140px * 0.57 + 50% - (1140px / 2));
    }
  }

  .number-blue {
    display: inline-block;
    width: 40px;
    background: $blue;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 26px;
    @include respond-to('desktop') {
      margin-bottom: 2px;
    }
  }

  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.77px;
    color: #000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet') {
      font-size: 40px;
      line-height: 48px;
    }
    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }

    p {
      margin-bottom: 0;
      @include respond-to('desktop') {
        margin-left: -2px;
      }
    }
  }

  &__tekst-right {
    font-size: 18px;
    line-height: 24px;
    color: #000;
    @include respond-to('tablet') {
      line-height: 32px;
    }
    @include respond-to('desktop') {
      font-size: 22px;
      line-height: 32px;
    }
    @include respond-to('hd') {
      width: calc(1140px * 0.57);
      display: inline-block;
      padding-right: 10px;
    }

    b, strong {
      font-family: $ubuntu-font;
      font-weight: bold;
    }

    &--bigger {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      @include respond-to('desktop') {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  span.blue {
    color: $blue;
  }

  .see-more {
    display: flex;
    align-items: center;
    width: 200px;
    z-index: 1;
    position: relative;
    font-size: 14px;
    line-height: 24px;

    &--link {
      cursor: pointer;
      color: $grey;
      font-size: 14px;
      line-height: 24px;
      text-decoration: underline;

      &:hover {
        color: $blue;
      }
    }
  }

  .grey-spacer {
    margin-top: 50px;
    @include respond-to('tablet') {
      margin-top: 34px;
    }
    @include respond-to('hd') {
      margin-bottom: 85px;
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

/*  Widok modala  */
#fundModal {
  @include respond-to('desktop') {
    max-width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      background: rgba($white, 0.9);
      width: 200vw;
      height: 100vh;
      left: calc(50vw / -2);
      top: 0;
    }
  }
}

.fund-solecki {
  padding: 54px 16px 0 16px;
  @include respond-to('tablet_more') {
    padding: 51px 24px 0 24px;
    display: flex;
    flex-flow: row wrap;
  }
  @include respond-to('desktop') {
    padding: 43px 40px 0 40px;
  }
  &__title {
    @extend h2;
    letter-spacing: -0.77px;
    margin-bottom: 22px;
    width: 100%;
    @include respond-to('tablet_more') {
      letter-spacing: -0.65px;
      line-height: 56px;
      margin-bottom: 45px;
    }
    @include respond-to('desktop') {
      letter-spacing: 0px;
      font-size: 56px;
      line-height: 68px;
      margin-bottom: 57px;
    }
  }

  &-box {
    margin-bottom: 49px;
    @include respond-to('tablet') {
      margin-bottom: 69px;
      width: 50%;
      &:nth-child(2n) {
        padding-right: 15px;
      }
      &:nth-child(2n-1) {
        padding-left: 15px;
      }
    }
    @include respond-to('desktop') {
      margin-bottom: 58px;
      display: flex;
      flex-direction: row;
      width: 100%;
    }

    &__image {
      margin-bottom: 40px;
      @include respond-to('tablet') {
        margin-bottom: 9px;
      }
      @include respond-to('desktop') {
        min-width: 640px;
        max-width: 640px;
        margin-right: 32px;
      }

      &--img {
        width: 100%;
      }
    }

    &__text {
      font-family: $ubuntu-font;
      font-size: 13px;
      line-height: 18px;
      color: #000;
      margin-bottom: 0;
      @include respond-to('desktop') {
        margin-top: 3px;
      }
    }

    &__value {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      color: #000;
      padding: 6px 0 11px 0;
      border-bottom: 2px solid rgba($grey, 0.3);
      margin-bottom: 0;
      @include respond-to('tablet_more') {
        padding-bottom: 14px;
      }
      @include respond-to('desktop') {
        padding: 5px 0;
      }
    }

    &__caption {
      font-family: $ubuntu-font;
      font-size: 14px;
      line-height: 24px;
      color: #000;
      margin-top: 12px;
      @include respond-to('tablet_more') {
        margin-top: 16px;
        padding-right: 16px;
      }
      @include respond-to('desktop') {
        font-size: 16px;
        margin-top: 14px;
      }
    }
  }
}
