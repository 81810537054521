.hp-what-say {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 0 16px;
  @include respond-to('phone') {
    max-width: 100%;
  }
  @include respond-to('tablet_more') {
    padding: 110px 26px 0 26px;
  }
  @include respond-to('desktop') {
    padding: 33px 10px 0 10px;
    max-width: 1140px;
  }
  .sticky-column-left {
    margin-bottom: -5px;
    @include respond-to('tablet_more') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
  }
  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.77px;
    color:#000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet_more') {
      font-size: 48px;
      line-height: 56px;
    }
    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
      padding-bottom: 105px;
    }
  }
  &__quote-column {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    margin-top: 58px;
    @include respond-to('tablet_more') {
      margin-top: 79px;
      &:first-child {
       margin-top: 35px;
      }
    }
    @include respond-to('tablet') {
      flex-wrap: nowrap;
      flex-flow: row;
    }
  }
  &__quote-box {
    width: 100%;
    margin-bottom: 24px;
    @include respond-to('tablet_more') {
      margin-bottom: 39px;
    }
    @include respond-to('hd') {
      margin-bottom: 27px;
    }
  }
  &__quote-sign {
    width: 96px;
    height: 96px;
    background-color: #eef1f7; //##TODO - dlaczego taki szary a nie typowy gray?
    color: $blue;
    line-height: 124px;
    font-size: 64px;
    font-family: $montserrat-font;
    font-weight: bold;
    letter-spacing: -1.03px;
    //margin-top: 53px;
    //margin-bottom: 63px;
    text-align: center;
  }
  &__quote-text {
    font-family: $ubuntu-font;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    margin-top: 38px;
    @include respond-to('tablet_more') {
      margin-top: 107px;
    }
    @include respond-to('tablet') {
      margin: 14px 0 0 33px;
    }
    @include respond-to('desktop') {
      margin-top: 63px;
      font-size: 22px;
      line-height: 32px;
    }
  }
  &__quote-name {
    font-size: 22px;
    line-height: 32px;
    font-family: $montserrat-font;
    font-weight: bold;
    color: #000;
    margin-top: 15px;
    margin-bottom: 0;
    max-width: 100%;
    @include respond-to('tablet') {
      margin-left: 40px;
    }
    @include respond-to('desktop') {
      margin-top: 30px;
      font-size: 32px;
      line-height: 40px;
    }
  }
  &__quote-who {
    font-size: 22px;
    line-height: 27px;
    font-family: $montserrat-font;
    font-weight: bold;
    color: $grey;
    margin: 3px 0 0 0;
    @include respond-to('tablet') {
      margin-left: 40px;
    }
    @include respond-to('desktop') {
      margin: 8px 0 0 0;
      font-size: 32px;
      line-height: 40px;
    }
  }
  .grey-spacer {
    margin-top: 48px;
    @include respond-to('tablet_more') {
      margin-top: 42px;
    }
    @include respond-to('hd') {
      margin-bottom: 85px;
    }
  }
  .list-what-say:first-child .hp-what-say__quote-column {
    @include respond-to('tablet_more') {
      margin-top: 35px;
    }
  }
}
