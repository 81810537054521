//######################################## LIST ##########################//

.news-list {
  width: 100%;

  .container {
    //max-width: 100%;
    padding: 0;


    @include respond-to('phone') {
      max-width: 100%;
      padding-left: 15px;
      padding-right: 15px;

    }
  }

  h1 {
    margin-top: 138px;
    font-family: $montserrat-font;
    font-size: 64px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: -1px;
    color: #000000;
    margin-bottom: 307px;
    @include respond-to('tabletandmobile') {
      font-size: 48px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: -1px;
      color: #000000;
      margin-top: 153px - 15px;
      margin-bottom: 136px;
    }
    @include respond-to('phone') {
      margin-bottom: 102px;
    }
  }
}

.module-list {
  margin-bottom: 168px;

  &__left {
    display: inline-block;
    vertical-align: top;
    width: 410px;
    margin-right: 40px;
    @include respond-to('tabletandmobile') {
      width: 100%;
      margin-right: 0;
    }

    &__h2 {
      font-family: $montserrat-font;
      font-size: 64px;
      font-weight: bold;
      letter-spacing: -1px;
      color: #000000;

      @include respond-to('tabletandmobile') {
        font-size: 48px;
        letter-spacing: -0.8px;
        margin-bottom: 76px;
        max-width: 600px;
      }

      @include respond-to('phone') {
        margin-bottom: 68px;
      }
    }
  }

  &__right {
    vertical-align: top;
    width: calc(100% - 455px);
    display: grid;
    float: right;
    grid-template-columns: 1fr 1fr;

    @include respond-to('tabletandmobile') {
      width: 100%;
    }
    @include respond-to('tablet') {
      grid-gap: 0 75px;
    }
    @include respond-to('phone') {
      grid-template-columns: 1fr;
      display: block;
    }

    //module-news
    .one-news {
      padding-left: 12px;
      padding-right: 12px;
      display: inline-block;
      vertical-align: top;
      float: left;
      margin-top: 32px;

      @include respond-to('phone') {
        width: 100%;
        margin-left: 0;
        margin-bottom: 35px;
        padding: 0;
      }

      @include respond-to('tablet') {
        padding: 0;

      }

      &:hover {
        text-decoration: none;
      }

      &__div-image {
        width: 315px;
        height: 208px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        @include respond-to('phone') {
          width: 100%;
        }

        &__image {
          width: 100%;
          height: auto;
          @include respond-to('phone') {
            width: 100%;
            height: auto;
          }
        }
      }

      &__under-image {
        height: 52px !important;
        width: 100%;
        font-family: $ubuntu-font;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 52px;
        letter-spacing: normal;
        border-bottom: 3px solid rgba($grey, 0.3);
        color: #000000;

        @include respond-to('phone') {
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 50px;
          letter-spacing: normal;
          color: #000000;
        }
        @include respond-to('tablet') {
          font-size: 14px;
        }

        &__left {
          float: left;
          text-align: left;
          width: 49%;
        }

        &__right {
          float: right;
          text-align: right;
          width: 49%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &__topic {
        margin-top: 15px;
        margin-bottom: 15px;

        &--h3 {
          font-family: $ubuntu-font;
          font-size: 22px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.45;
          letter-spacing: normal;
          color: #000000;

          @include respond-to('phone') {
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.78;
            letter-spacing: normal;
            color: #000000;
          }

          @include respond-to('tablet') {
            font-size: 18px;
            line-height: 1.78;
          }
        }
      }
    }
  }

  &__pagination {
    width: 100%;
    float: right;
    margin-top: 85px;
    margin-bottom: 168px;
    @include respond-to('phone') {
      margin-top: 18px;
      margin-bottom: 63px;
    }
    @include respond-to('tablet') {
      margin-bottom: 81px;
    }

    &__text {
      float: right;
      font-family: $montserrat-font;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 40px;
      letter-spacing: normal;
      color: #000000;
    }

    &__button {
      width: 40px;
      height: 40px;
      float: right;
      margin-left: 10px;
      background: #000;
      transition: background-color 0.3s;

      &:hover {
        background-color: $blue;
      }

      .icon {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 5px;
        margin-top: 14px;

        &__right {
          transform: rotate(-45deg);
          margin-left: 10px;
        }

        &__left {
          transform: rotate(135deg);
          margin-left: 16px;
        }
      }
    }
  }
}

//############################################### DETAILS ########################################//
.container-news {
  padding: 0;

  @include respond-to('phone') {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include respond-to('tablet') {
    width: 768px;
  }
}

.details-news {
  width: 100%;

  a {
    font-size: 22px;
    @include respond-to('tabletandmobile') {
      font-size: 18px;
    }
  }

  .container {
    //max-width: 100%;
    margin: auto;
    padding: 0;

    @include respond-to('tablet') {
      max-width: 100%;
      padding-right: 38px;
      padding-left: 41px;
    }

    @include respond-to('phone') {
      max-width: 280px;
      padding: 0;

    }

    @include respond-to('desktop') {
      display: flex;

    }
  }

  &__left {
    display: inline-block;
    vertical-align: top;
    width: 443px;
    top: 64px;
    margin-top: 75px;
    position: sticky;
    position: -webkit-sticky;
    margin-right: 36px;
    @include respond-to('hd') {
      padding-top: 30px;
      margin-top: 45px;
    }
    @include respond-to('tablet') {
      width: 100%;
      position: static;
      margin-bottom: 45px;
      margin-top: 372px;
    }
    @include respond-to('phone') {
      width: 100%;
      position: static;
      margin-top: 290px;
      margin-right: 0;
      margin-bottom: 50px;
    }

    &__topic {
      margin-bottom: 40px;

      &--h1 {
        font-family: $montserrat-font;
        font-size: 64px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: -1px;
        color: #000000;

        @include respond-to('tabletandmobile') {
          font-size: 48px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.17;
          letter-spacing: -0.8px;
          color: #000000;
        }

        @include respond-to('phone') {
          margin-bottom: 0;
        }

        @include respond-to('tablet') {
          width: 100%;
          max-width: 600px;
          word-break: inherit;
          margin-bottom: 60px;
        }

        @include respond-to('desktop') {
          letter-spacing: -1.23px;
        }

      }
    }

    &__under-topic {
      height: 50px;
      width: 100%;
      font-family: $ubuntu-font;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 50px;
      letter-spacing: normal;
      border-bottom: 3px solid rgba($grey, 0.3);
      color: #000000;

      @include respond-to('phone') {
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 50px;
        letter-spacing: normal;
        color: #000000;
      }

      @include respond-to('tablet') {
        border-bottom: 6px solid rgba($grey, 0.3);
        height: 56px;
      }

      @include respond-to('desktop') {
        border-bottom: 5px solid rgba($grey, 0.3);
        height: 55px;
        margin-bottom: 40px;
      }

      &__left {
        float: left;
        text-align: left;
        width: 49%;
        @include respond-to('phone') {
          font-size: 16px;
        }
      }

      &__right {
        float: right;
        text-align: right;
        width: 49%;
      }
    }
  }

  &__right {
    width: 656px;
    display: inline-block;
    vertical-align: top;
    @include respond-to('tabletandmobile') {
      width: 100%;
    }

    &__div-image {
      width: 682px;
      display: flex;
      overflow: hidden;
      @include respond-to('tablet') {
        width: 100%;
        position: absolute;
        top: 64px;
        left: 0;
        height: 320px;
        align-items: center;
      }
      @include respond-to('phone') {
        width: 100%;
        top: 64px;
        position: absolute;
        left: 0;
        align-items: center;
        height: 254px;
      }
      @include respond-to('tablet_more') {
        height: 321px;
        align-items: center;
      }

      &__image {
        width: 100%;
        height: auto;
        @include respond-to('tablet') {
          width: calc(100% + 15px);
        }
        @include respond-to('phone') {
          width: calc(100% + 15px);
        }
      }
    }

    &__content {
      word-break: break-word;
      @include respond-to('desktop') {
        padding-right: 32px;
      }

      p {
        font-family: $ubuntu-font;
        font-size: 22px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.09;
        letter-spacing: normal;
        color: #000000;
        margin-top: 41px;
        margin-bottom: 51px;
        @include respond-to('tabletandmobile') {
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #000000;

        }
        @include respond-to('phone') {
          margin-top: 41px;
          img {
            width: 100vw;
            height: auto;
            margin-left: -15px;
            margin-right: -15px;
            max-width: 100vw;
          }
        }
        @include respond-to('tablet') {
          img {
            width: 100%;
            height: auto;
          }
        }
        @include respond-to('desktop') {
          img {
            width: 100%;
            height: auto;
          }
        }
      }

      h2 {
        font-family: $montserrat-font;
        font-size: 56px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        margin-top: 43px;
        margin-bottom: 51px;

        @include respond-to('tabletandmobile') {
          font-size: 40px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          word-break: break-word;
        }
      }

      h3 {
        font-family: $montserrat-font;
        font-size: 32px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #000000;
        margin-top: 43px;
        margin-bottom: 51px;

        @include respond-to('tabletandmobile') {
          font-size: 22px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.45;
          letter-spacing: normal;
          color: #000000;
        }
      }

      blockquote {
        font-family: $ubuntu-font;
        font-size: 22px;
        font-weight: bold;
        font-style: italic;
        font-stretch: normal;
        width: 500px;
        line-height: 1.09;
        letter-spacing: normal;
        color: $blue;
        margin-top: 43px;
        margin-bottom: 51px;

        @include respond-to('phone') {
          width: 100%;
        }
      }

      a {
        text-decoration: underline;
      }
    }

    &__back-button {
      width: 255px;
      height: 64px;
      background-color: #6f83ff;
      margin-bottom: 98px;
      margin-top: 60px;
      transition: 0.3s;

      @include respond-to('phone') {
        position: relative;
        left: 50%;
        transform: translate(-50%);
        margin-bottom: 92px;
        margin-top: 92px;
      }

      @include respond-to('tablet') {
        margin-bottom: 106px;
        margin-top: 45px;
      }

      &:hover {
        text-decoration: none;
        background: #5e77ff;
        color: #fff;
      }

      &--blue {
        font-family: $montserrat-font;
        font-size: 12px !important;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 68px;
        text-transform: uppercase;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  &__social {
    display: flex;

    .fb-like,
    .fb-like iframe{
      min-width: 161px !important;
    }

    .twitter-share-button {
      margin-left: 4px;
    }
  }
}
