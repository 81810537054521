.filter-container {
  @include respond-to(desktop) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  .home-link {
    display: inline-block;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1.71;
    margin-bottom: 22px;
    @include respond-to(desktop) {
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  .filter {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: -40px;
    z-index: 1;
    position: relative;
    @include respond-to(desktop) {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
    }

    .part:first-child {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .display-type-selector {
      display: flex;

      a {
        display: flex;
        min-width: 22px;
        max-width: 22px;
        height: 22px;
        margin: 0 4px;

        span {
          display: block;
          background-color: $grey;
        }

        &:hover, &.active {
          span {
            background-color: $blue;
          }
        }

        &.type-boxes {
          flex-wrap: wrap;

          span {
            min-width: 9px;
            max-width: 9px;
            height: 9px;
            margin: 1px;
          }
        }

        &.type-list {
          flex-direction: column;
          justify-content: center;

          span {
            min-height: 4px;
            max-height: 4px;
            margin: 2px 0;
          }
        }
      }
    }

    .tag-selector {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      @include respond-to(desktop) {
        margin-bottom: 0;
      }

      .label {
        color: $grey;
        font-size: 14px;
        line-height: 1.71;
        min-width: 100%;
        margin-bottom: 1px;
        @include respond-to(desktop) {
          min-width: auto;
          margin-bottom: 0;
          margin-right: 12px;
          font-size: 12px;
        }
      }

      ul {
        margin: 0;
        @include respond-to(desktop) {
          display: flex;
          align-items: center;
        }

        li {
          margin: 0 8px 8px 0;
          display: inline-block;
          @include respond-to(desktop) {
            margin-bottom: 0;
            display: block;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      a {
        font-size: 10px;
        font-weight: bold;
        line-height: 1.8;
        border-radius: 22.5px;
        border: solid 1px $grey;
        display: block;
        padding: 0 8px;

        &.active {
          background-color: $violet-4;
          border-color: $violet-4;
          color: $blue;
        }
      }
    }

    .sort-dropdown {
      position: relative;

      .current-selection {
        font-size: 14px;
        line-height: 1.17;
        color: $grey;
        padding-right: 15px;
        cursor: pointer;
        @include respond-to(desktop) {
          font-size: 12px;
        }

        &::after {
          content: "";
          position: absolute;
          border-right: 2px solid currentColor;
          border-bottom: 2px solid currentColor;
          transform: rotate(45deg);
          right: 0;
          top: calc(50% - 3px);
          box-sizing: border-box;
          width: 5px;
          height: 5px;
        }
      }

      ul {
        display: none;
        position: absolute;
        padding: 20px;
        margin: 0;
        list-style: none;
        background-color: $white;
        box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
        top: 24px;
        left: 50%;
        @include respond-to(desktop) {
          left: -20px;
        }

        li {
          padding: 6px 2px;
          border-bottom: 1px solid $grey;
          text-align: center;
          white-space: nowrap;
          line-height: normal;

          &:last-child {
            border-bottom: 0;
          }

          a {
            font-size: 14px;
            line-height: 1.71;
            @include respond-to(desktop) {
              font-size: 12px;
            }
          }
        }
      }

      &.active {
        ul {
          display: block;
        }
      }
    }
  }
}
