.city-intro {
  min-height: 100vh;
  @include respond-to('phone') {
    margin-bottom: 21px;
  }
  @include respond-to('tablet') {
    margin-bottom: 106px - 84px;
  }

  &.container {
    @include respond-to('mobile') {
      max-width: 100%;

      .row {
        flex-direction: column;

        .city-intro--left {
          flex: none;
        }

        .city-intro--right {
          flex: 1;
        }
      }
    }
    @include respond-to('desktop') {
      max-width: 100%;
    }

    .row {
      height: 100vh;
      @include respond-to('desktop') {
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        min-height: 310px;
        margin-bottom: 33px;
      }
    }
  }

  &--left {
    @include respond-to('desktop') {
      z-index: 1;
      position: absolute;
      left: 40px;
      width: calc(41% - 40px);
    }
    @include respond-to('hd') {
      padding-left: 88px;
    }
  }

  &--right {
    @include respond-to('desktop') {
      max-width: 59%;
      height: 100vh;
    }
  }

  &--title {
    margin-top: 0;
    padding: 34px 1px 15px 1px;
    @include respond-to('tablet_more') {
      padding: 34px 0 23px 0;
      font-size: 48px;
      line-height: 56px;
    }
    @include respond-to('desktop') {
      font-family: $montserrat-font;
      font-size: 64px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: -1px;
      color: #000000;
    }
    @include respond-to('hd') {
      font-size: 100px;
      letter-spacing: -1.6px;
      line-height: 1.1em;
    }
  }

  &--budget-text {
    @extend h3;
    display: inline-block;
    font-weight: 400;
    padding: 0 1px 3px 1px;
    margin-bottom: 0;
    font-size: 22px;
    line-height: 32px;
    @include respond-to('tablet_more') {
      padding: 0 0 8px 0;
      font-size: 32px;
      line-height: 40px;
    }
    @include respond-to('desktop') {
      letter-spacing: 2px;
    }
    @include respond-to('hd') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }
  }

  &--city-logo {
    display: flex;
    align-items: center;
    margin-top: 26px;
    margin-bottom: 20px;
    @include respond-to('phone') {
      margin-top: 10px;
    }
    @include respond-to('tablet') {
      margin-top: 46px;
      margin-bottom: 44px;
    }
    @include respond-to('desktop') {
      margin-bottom: 0;
      margin-top: 75px;
    }
    @include respond-to('hd') {
      margin-top: 130px;
    }

    img {
      height: 100%;
      width: auto;
      max-height: 350px;
    }
  }

  &--city-photo {
    margin: 0 -15px;
    background: center no-repeat;
    background-size: cover;
    @include respond-to('desktop') {
      height: 100vh;
    }
    @include respond-to('mobile') {
      height: 100%;
    }
  }

  &--dropdown {
    display: inline-block;
    position: relative;
    vertical-align: top;
    left: 4px;
    top: 0;
    @include respond-to('tablet') {
      left: 12px;
      top: -8px;
    }
    @include respond-to('desktop') {
      left: 12px;
      top: -4px;
    }
    @include respond-to('hd') {
      left: 12px;
      top: -8px;
    }

    .dropdown-year-button {
      padding: 0;
      font-size: 22px;
      color: #000;
      font-weight: bold;
      font-family: $montserrat-font;
      background: transparent;
      border: 0 solid;
      height: auto;
      width: auto;
      @include respond-to('tablet_more') {
        font-size: 32px;
      }
      @include respond-to('hd') {
        font-size: 64px;
        letter-spacing: -1.03px;
      }

      &:hover, &:focus {
        background: transparent !important;
      }

      &:focus {
        box-shadow: $btn-focus-box-shadow !important;
      }

      &:after {
        border: solid black;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        vertical-align: baseline;
        padding: 3px;
        top: -5px;
        position: relative;
        transition: 0.3s;
        @include respond-to('tablet_more') {
          top: -8px;
        }
        @include respond-to('hd') {
          border-width: 0 6px 6px 0;
          padding: 6px;
          top: -13px !important;
          margin-left: 0;
          vertical-align: baseline;
        }
      }

      &[aria-expanded="true"]:after {
        transform: rotate(-135deg);
        top: -2px;
      }
    }

    .dropdown-menu {
      border-radius: 2px;
      box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
      background-color: $white;
      min-width: 144px;
      padding: 10px 24px 4px 24px;
      border: 0;
      left: 50% !important;
      margin-top: 0;
      transform: translate3d(-50%, 32px, 0px) !important;
      @include respond-to('tablet_more') {
        top: 16px !important;
      }
      @include respond-to('hd') {
        top: 45px !important;
      }

      .dropdown-item {
        padding: 7px 14px 7px 8px;
        text-align: center;
        border-bottom: 1px solid $grey;
        color: $grey;
        background: transparent;
        transition: 0.3s;

        &:last-child {
          border-bottom: 0 solid;
        }

        &:hover {
          background: transparent;
          color: $blue;
        }

        span {
          @extend h3;
          display: block;
        }
      }
    }
  }
}
