.hp-presentation {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 39px 16px 0 16px;
  @include respond-to('phablet') {
    max-width: 100%;
  }
  @include respond-to('tablet') {
    padding: 83px 26px 0 26px;
  }
  @include respond-to('desktop') {
    padding: 97px 10px 0 10px;
    max-width: 1140px;
  }
  @include respond-to('hd') {
    padding-top: 69px;
  }
  .sticky-column-left {
    @include respond-to('tablet') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
  }
  .column-right {
    @include respond-to('desktop') {
      position: relative;
      top: -59px;
    }
    @include respond-to('hd') {
      top: -73px;
    }
  }
  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.77px;
    color: #000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet') {
      font-size: 48px;
      line-height: 56px;
    }
    @include respond-to('netbook') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
      padding-bottom: 105px;
    }
    &-small {
      font-family: $montserrat-font;
      font-size: 22px;
      font-weight: bold;
      line-height: 32px;
      color: #000;
      margin-top: 12px;
      margin-bottom: 0;
      display: block;
      @include respond-to('tablet') {
        margin-top: 5px;
      }
      @include respond-to('desktop') {
        margin-top: -5px;
        font-size: 32px;
        line-height: 40px;
        padding-right: 60px;
      }
    }
  }
  .list-presentaion {
    margin-bottom: 40px;
    margin: 40px -11px 12px -11px;
    display: flex;
    flex-flow: row wrap;
    @include respond-to('tablet') {
      margin-top: 36px;
      margin-bottom: 47px;
    }
    @include respond-to('desktop') {
      margin-top: 0;
      margin-bottom: 2px;
    }
    @include respond-to('hd') {
      margin-bottom: 1px;
    }
  }
  .grey-spacer {
    margin-top: 40px;
    @include respond-to('tablet') {
      margin-top: 42px;
    }
    @include respond-to('hd') {
      margin-bottom: 85px;
    }
  }
}
.hp-list-presentation {
  padding: 0 11px;
  @include respond-to('tablet_more') {
    padding: 0 41px;
  }
  @include respond-to('desktop') {
    margin-bottom: 45px;
    padding: 0 56px;
    &:nth-child(2n) {
      padding-right: 0;
    }
    &:nth-child(2n-1) {
      padding-left: 0;
    }
    > * {
      max-width: 260px;
    }
  }
  @include respond-to('hd') {
    margin-bottom: 32px;
  }
  &__img {
    max-width: 152px;
    max-height: 156px;
    margin-bottom: 12px;
    @include respond-to('hd') {
      margin-bottom: 14px;
    }
    img {
      max-height: 156px;
      @include respond-to('desktop') {
        max-height: 118px;
      }
    }
  }
  &__value {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: $blue;
    margin-top: 12px;
    @include respond-to('tablet_more') {
      margin-top: 3px;
    }
    @include respond-to('desktop') {
      margin-top: 0px;
      font-size: 32px;
      line-height: 39px;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 24px;
    color: #000;
    margin-top: 8px;
    max-width: 90%;
    margin-bottom: 48px;
    @include respond-to('tablet') {
      margin-bottom: 18px;
    }
    @include respond-to('desktop') {
      font-size: 16px;
      margin-top: 23px;
    }
  }
}
