.intro {
  padding: 95px 0;
  @include respond-to(tablet_more) {
    padding: 215px 0 260px;
  }

  h1 {
    margin-bottom: 45px;
    @include respond-to(tablet_more) {
      max-width: 737px;
      margin-bottom: 62px;
    }
  }

  h2 {
    color: $blue;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 20px;
    @include respond-to(tablet_more) {
      font-size: 32px;
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
    @include respond-to(tablet_more) {
      font-size: 16px;
    }
  }
}
