.error-page {
  background: $dark-grey;
  width: 100%;
  height: 100%;
  max-width: 100%;
  .navbar {
    width: 100%;
    left: 0;
    a, a:hover {
      color: #fff;
    }
  }
  .row, .col {
    height: 100%;
  }
  &--title {
    text-align: center;
    font-family: $montserrat-font;
    font-size: 64px;
    font-weight: bold;
    line-height: 80px;
    letter-spacing: 2.8px;
    color: rgba($white, 0.2);
    margin-top: 129px;
  }
  &--subtitle {
    font-family: $montserrat-font;
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    text-align: center;
    color: $white;
    margin-top: 53px;
  }
  &--back {
    position: absolute;
    top: 57%;
    left: 40px;
  }
  &--header {
    font-family: $montserrat-font;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: $white;
    margin-bottom: 15px;
  }
  &--link {
    color: rgba($white, 0.5);
    display: block;
    font-size: 13px;
    line-height: 40px;
    font-family: $ubuntu-font;
  }
}
