.pit-info {
  @include respond-to('tablet_more') {
    padding: 121px 11px 76px 11px;
  }
  @include respond-to('desktop') {
    padding: 103px 45px 58px 35px;
  }

  &--section {
    width: 100%;
  }

  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
    letter-spacing: -1.03px;
    color: #000;
    margin-bottom: 121px;

    &--title {
      letter-spacing: -0.77px;
      margin-bottom: 0;
      @include respond-to('tablet_more') {
        letter-spacing: -1.03px;
      }
    }
  }

  .list-wrote {
    font-family: $ubuntu-font;
    font-size: 18px;
    line-height: 21px;
    margin-right: -11px;
    margin-left: -11px;
    @include respond-to('desktop') {
      font-size: 22px;
      line-height: 26px;
      margin: 0;
      padding-top: 8px;
    }

    [class*="col-"] {
      padding-right: 11px;
      padding-left: 11px;
      @include respond-to('tablet_more') {
        padding-right: 41px;
      }
      @include respond-to('desktop') {
        padding-right: 0;
        padding-left: 0;
      }
    }

    small {
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__text {
    margin-bottom: 0;
    display: flex;
    font-size: 32px;
    line-height: 40px;
    font-family: $montserrat-font;
    font-weight: bold;
    @include respond-to('tablet_more') {
      max-width: 572px;
    }

    &--note {
      font-size: 16px;
      line-height: 24px;
      font-family: $ubuntu-font;
      font-weight: 400;
      @include respond-to('phone') {
        margin-bottom: -18px;
      }
      @include respond-to('tablet_more') {
        max-width: 580px;
      }
    }

    &--bigger-note {
      font-size: 22px;
      line-height: 32px;
      font-family: $ubuntu-font;
      font-weight: 400;
      margin-top: 31px;
      @include respond-to('phone') {
        margin-bottom: -3px;
      }
    }

    &--blue {
      font-size: 56px;
      line-height: 68px;
      margin: 23px -11px 63px 0;
      color: $blue;
      @include respond-to('tablet_more') {
        margin: 31px 0px 105px 0;
      }
    }
  }

  &__porthole {
    max-width: calc(100vw - 11px);
    overflow: hidden;
    margin-right: -11px;

    &--smaller-photo, &--bigger-photo {
      margin-top: 28px;
      margin-bottom: 56px;
      @include respond-to('tablet') {
        margin-top: 172px;
      }
      @include respond-to('desktop') {
        margin-top: 111px;
        margin-bottom: 117px;
      }
    }

    &--bigger-photo {
      .pit-info--img {
        max-height: 189px;
      }
    }

    &--normal-photo {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 70px;
      @include respond-to('phone') {
        margin-bottom: 81px;
      }
      @include respond-to('tablet') {
        display: flex;
        justify-content: flex-end;
      }
      @include respond-to('tablet_more') {
        margin-top: -65px;
      }

      .pit-info--img {
        max-height: 250px;
        @include respond-to('tablet_more') {
          max-height: 425px;
        }
      }
    }
  }

  &--finish {
    @include respond-to('tablet_more') {
      display: flex;
    }
  }

  &--img {
    max-height: 89px;
    position: relative;
  }

  &--img-blue {
    display: none;
    @include respond-to('tablet_more') {
      display: inline-block;
      height: 309px;
      position: absolute;
      right: 16px;
    }
    @include respond-to('desktop') {
      right: 99px;
      height: 280px;
      top: 101px;
    }
  }

  &--header {
    letter-spacing: -0.77px;
    line-height: 56px;
    padding-right: 40px;
    padding-bottom: 152px;

    @include respond-to('phone') {
      padding-top: 103px;
    }
    @include respond-to('tablet_more') {
      font-size: 48px;
      line-height: 56px;
      padding-bottom: 140px;
    }
    @include respond-to('tablet') {
      padding-bottom: 137px;
    }

    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
      padding-bottom: 286px;
    }
  }

  &--blue {
    font-family: $montserrat-font;
    font-weight: bold;
    background: $blue;
    margin-right: -11px;
    margin-left: -11px;
    padding: 33px 11px 35px 11px;
    color: $white;
    letter-spacing: -0.77px;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 49px;

    @include respond-to('phone') {
      margin: 0 -15px 49px -15px;
    }
    @include respond-to('tablet_more') {
      margin-right: 0;
      margin-left: 0;
      padding: 60px 16px 29px 38px;
      height: 404px;
      margin-bottom: 111px;
    }
    @include respond-to('desktop') {
      padding: 44px 235px 44px 38px;
      height: 325px;
      font-size: 64px;
      line-height: 80px;
      margin-bottom: 136px;
      position: relative;
      margin-right: -35px;
      margin-left: -25px;
    }
  }

  .number-blue {
    display: inline-block;
    width: 112px;
    background: $blue;
    line-height: 112px;
    text-align: center;
    color: #fff;
    font-size: 64px;
    font-weight: bold;
    letter-spacing: -1.03px;
    margin-bottom: 47px;
    font-family: 'Montserrat', sans-serif;
    @include respond-to('tablet') {
      margin-bottom: 63px;
    }
    @include respond-to('desktop') {
      margin-bottom: 63px;
    }


    &--position {
      margin-top: 63px;
      @include respond-to('tablet_more') {
        margin-top: 164px;
        margin-bottom: 70px;
      }
    }

    &--position2 {
      margin-bottom: 83px;
    }
  }

  .grey-spacer {
    margin-top: 45px;
    margin-bottom: 63px;
    height: 8px;
    @include respond-to('tablet_more') {
      margin-top: 78px;
      height: 7px;
      margin-bottom: 107px;
    }
    @include respond-to('desktop') {
      margin-top: 33px;
    }
    @include respond-to('desktop') {
      margin-top: 100px;
      height: 8px;
      margin-bottom: 157px;
    }
  }

  .pr-6 {
    @include respond-to('desktop') {
      padding-right: 6px;
    }
  }

  .tablet--vis {
    display: none;
    @include respond-to('tablet_more') {
      display: inline;
    }
  }
}
