div.google-visualization-tooltip {
    all: unset;

}

.chart_tooltip {
  height: 18px;
  font-family: Ubuntu;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #000000;
}

.tooltip_title {
  width: 44px;
  height: 16px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.tooltip_budget {
  width: 54px;
  height: 18px;
  font-family: Ubuntu;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: #000000;
  float: right
}
