.section {
  padding-top: 72px;
  padding-bottom: 72px;

  &:last-child {
    padding-bottom: 0;
  }

  .title-container {
    margin-bottom: 34px;

    .number {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      display: inline-block;
      width: 40px;
      margin-bottom: 16px;
      background: $blue;
      color: $white;
    }

    h2 {
      font-size: 22px;
      line-height: 32px;
      @include respond-to(tablet_more) {
        font-size: 48px;
        line-height: 60px;
      }
    }
  }

  &.non-client-charts {
    padding-top: 0;

    .title-container {
      @include respond-to(tablet_more) {
        display: flex;
        align-items: flex-end;
        margin-bottom: 95px;
      }

      .title {
        margin-bottom: 34px;
        @include respond-to(tablet_more) {
          margin-right: 15%;
          margin-bottom: 0;
        }
      }

      .description {
        font-size: 16px;
        @include respond-to(tablet_more) {
          font-size: 22px;
          margin-bottom: -5px;
        }
      }
    }
  }

  .graph-container {
    margin-bottom: 16px;
    @include respond-to(tablet_more) {
      width: calc(100% - 100px);
      margin: 0 auto 37px;
    }

    .title {
      font-size: 16px;
      text-align: right;
      margin-bottom: 12px;
      @include respond-to(tablet_more) {
        font-size: 22px;
        padding-right: 70px;
        margin-bottom: 16px;
      }
    }

    .graph {
      border-radius: 16px;
      border: solid 2.5px #eef1f8;
      padding: 16px;
      @include respond-to(tablet_more) {
        padding: 24px;
      }

      .graph-header {
        margin-bottom: 24px;
        @include respond-to(tablet_more) {
          display: flex;
          justify-content: space-between;
        }

        h3 {
          font-size: 22px;
          line-height: 32px;
          margin-bottom: 16px;
          @include respond-to(tablet_more) {
            font-size: 19px;
            margin-bottom: 0;
          }
        }

        .legend {
          list-style: none;
          padding: 0;
          margin: 0;
          @include respond-to(tablet_more) {
            display: flex;
            align-items: center;
          }

          li {
            font-family: $montserrat-font;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            @include respond-to(tablet_more) {
              font-size: 13px;
              margin-bottom: 0;
              margin-left: 25px;
            }

            &::before {
              content: '';
              display: block;
              width: 19px;
              height: 19px;
              background-color: #023d9b;
              margin-right: 10px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:nth-child(2)::before {
              background-color: $violet-1;
            }

            &:nth-child(3)::before {
              background-color: $blue;
            }
          }
        }

        .lead {
          font-size: 13px;
          margin: 0;
          max-width: 170px;
          color: #b1b7c0;
        }
      }

      .chart-container {
        .units {
          font-size: 10px;
          font-weight: bold;
          color: #b1b7c0;
          margin-bottom: 8px;
          @include respond-to(tablet_more) {
            display: none;
          }
        }
      }
    }
  }

  .line-charts {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    .graph-container:last-child {
      margin-bottom: 0;
    }
  }

  .column-charts {
    .graph-container {
      margin-bottom: 0;
      @include respond-to(desktop) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .graph {
        margin-bottom: 16px;
        @include respond-to(desktop) {
          min-width: calc(33% - 8px);
          max-width: calc(33% - 8px);
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .graph-header {
          @include respond-to(tablet_more) {
            display: block;

            .lead {
              max-width: none;
              white-space: nowrap;
            }
          }
        }

        .chart-entries {
          height: 120px;
          display: flex;
          align-items: flex-end;
          justify-content: center;

          .entry {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin: 0 28px;
            text-align: center;
            font-family: $montserrat-font;
            font-weight: bold;
            font-size: 13px;

            .label, .bar {
              margin-bottom: 8px;
            }

            .bar {
              width: 16px;
              background-color: #023d9b;
              flex: auto;
              display: flex;
              flex-direction: column-reverse;

              &.light {
                background-color: $blue;
              }

              .part {
                background-color: $blue;
              }
            }
          }
        }
      }
    }
  }
}
