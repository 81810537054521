.city-investments {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 64px 16px;
  @include respond-to('phone') {
    padding-top: 24px;
  }

  .see-more {
    @include respond-to('phone') {
      margin-bottom: -10px;
    }
  }

  .type-of-budget {
    width: 100%;
    float: left;
    cursor: pointer;
    @include respond-to('phone') {
      display: flex;
      flex-flow: column;
      margin-top: 30px;
    }

    &__block {
      float: left;
      @include respond-to('phone') {
        display: block;
        margin-bottom: 25px;
      }

      &__txt {
        width: 100%;

        &__value {
          width: 100%;
          @include respond-to('phone') {
            height: 15px;
          }

          &--normal {
            font-family: $ubuntu-font;
            font-size: 13px;
            line-height: 1.38;
            color: #000000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include respond-to('phone') {
              overflow: visible;
            }
          }

          &--bold {
            font-weight: bold;
            font-family: $montserrat-font;
            font-size: 16px;
            line-height: 1;
            color: #000000;
          }
        }
      }

      &__bar {
        margin-top: 15px;
        width: 100%;
        height: 24px;

        &--grey {
          background-color: rgba(#b1b7c0, 0.34);
          transition: 0.3s;

          &:hover {
            background-color: $violet-1;
          }
        }

        &--lightgrey {
          background-color: rgba(177, 183, 192, 0.2);
          transition: 0.3s;

          &:hover {
            background-color: $violet-1;
          }
        }
      }

      &.active {
        .type-of-budget__block__txt {
          .type-of-budget__block__txt__value {
            &.type-of-budget__block__txt__value--normal, &.type-of-budget__block__txt__value--bold {
              color: $blue;
            }
          }
        }

        .type-of-budget__block__bar {
          &.type-of-budget__block__bar--grey {
            background-color: $blue;
          }
        }

        .type-of-budget__block__bar {
          &.type-of-budget__block__bar--lightgrey {
            background-color: $blue;
          }
        }
      }
    }
  }

  .info-all {
    width: 100%;
    float: left;

    .info-type {
      width: 100%;
      margin-top: 50px;
      position: relative;

      &.hide {
        display: none;
      }

      &__bar {
        width: 100%;
        border-bottom: 2px solid rgba(177, 183, 192, 0.3);
        padding-top: 21px;
        clear: both;
        @include respond-to('phone') {
          display: none;
        }
        @include respond-to('phone') {
          display: none;
        }
      }

      &__describe {
        font-family: $ubuntu-font;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 18px;
        letter-spacing: normal;
        color: #000000;
      }

      &__topic {
        float: left;

        &--blue {
          color: $blue;
          font-family: $montserrat-font;
          font-size: 32px;
          font-weight: bold;
          line-height: 1.25;
          display: none;
          transition: 0.3s;
          @include respond-to('phone') {
            margin-left: 0;
          }

          &.active {
            display: block;
          }

        }

        &--black {
          font-family: $montserrat-font;
          font-size: 32px;
          font-weight: bold;
          line-height: 1.25;
          color: #000;
          cursor: pointer;
          padding-right: 33px;

          &:after {
            content: "";
            position: relative;
            display: inline-block;
            border: solid black;
            border-width: 0 4px 4px 0;
            padding: 4px;
            top: -6px !important;
            margin-left: 20px;
            vertical-align: baseline;
            transform: rotate(45deg);
            transition: 0.3s;
          }

          &[aria-expanded="true"] {
            &:after {
              transform: rotate(-135deg);
              top: -2px !important;
            }
          }
        }
      }

      .dropdown-topic-list {
        border-radius: 2px;
        box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
        background-color: $white;
        min-width: 144px;
        padding: 10px 24px 4px 24px;
        border: 0;
        left: 0 !important;
        margin-top: 0;
        transform: translate3d(0%, 32px, 0px) !important;
        @include respond-to('tablet_more') {
          top: 30px !important;
        }
        @include respond-to('hd') {
          top: 45px !important;
        }

        .dropdown-item {
          padding: 7px 14px 7px 8px;
          text-align: center;
          border-bottom: 1px solid $grey;
          color: $grey;
          background: transparent;
          transition: 0.3s;

          &:last-child {
            border-bottom: 0 solid;
          }

          &.active, &:hover {
            background: transparent;
            color: $blue;
          }

          span {
            @extend h3;
            display: block;
          }
        }
      }

      .slick-list {
        margin-right: -40px;
      }

      .slick-slide {
        margin-right: 40px;
      }

      &__slider-info {
        width: 100%;
        float: left;
        margin-top: 24px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: -9999px;

        &.active {
          opacity: 1;
          visibility: visible;
          position: relative;
          left: 0;
        }

        .box-invest {
          &.odd {
            display: flex !important;
            flex-flow: column;

            .box-invest__image {
              order: 9;
            }
          }

          &__image {
            width: 100%;
            height: 208px;
            overflow: hidden;
            align-items: center;
            display: flex;
            justify-content: center;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          &__text {
            width: 100%;
            margin-top: 30px;
            float: left;
            @include respond-to('tablet_more') {
              width: 317px;
            }

            &--describe {
              font-family: $ubuntu-font;
              font-size: 13px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 18px;
              letter-spacing: normal;
              color: #000000;

              p.title {
                font-size: 16px;
                line-height: 18px;
                font-weight: bold;
                margin-bottom: 5px;
              }

              p:last-child {
                margin-bottom: 0;
              }
            }

            &--topic {
              font-family: $ubuntu-font;
              font-size: 13px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.38;
              letter-spacing: normal;
              color: #000000;
            }

            &--bold {
              font-family: $montserrat-font;
              font-size: 32px;
              font-weight: bold;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.25;
              letter-spacing: normal;
              color: #000000;
              margin-top: 5px;
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .see-more {
      display: flex;
      align-items: center;
      height: 64px;
      width: 200px;
      z-index: 1;
      position: relative;
      margin-top: 30px;

      &--link {
        cursor: pointer;
        color: #b1b7c0;
        font-size: 16px;
        line-height: 24px;
        text-decoration: underline;
        display: none;

        &.active {
          display: block;
        }

        &:hover {
          color: $blue;
        }
      }
    }

    .slick {
      &-list {
        @include respond-to('tablet_more') {
          padding-bottom: 100px;
        }
      }

      &-dots {
        padding-left: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        position: absolute;
        bottom: 0;
        width: 100%;

        li {
          background: rgba($grey, 0.3);
          float: left;
          flex-basis: 0;
          flex-grow: 1;
          max-width: 100%;

          &.slick-active {
            background: $blue;
          }

          button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 100%;
            height: 2px;
            padding: 0;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
          }
        }
      }

      &-arrow {
        bottom: 15px;
        top: auto;
        left: auto !important;
      }
    }
  }
}

