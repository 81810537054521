.box-cities {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(177, 183, 192, 0.3);
  background-color: $white;
  margin: 16px 1px 36px 1px;
  overflow: hidden;
  width: 100%;
  @include respond-to('desktop') {
    margin: 16px 1px;
  }

  &--img {
    width: 100%;
    height: 219px;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: auto;
    }
  }

  &--caption {
    padding: 10px 16px 58px 16px;
    display: flex;
    flex-wrap: nowrap;
    min-height: 108px;
    justify-content: space-between;
    @include respond-to('tablet_more') {
      padding: 16px;
      min-height: 130px;
    }
    @include respond-to('desktop') {
      padding: 23px 16px 16px 16px;
    }
  }

  &--city-name {
    .box-cities--link {
      color: #000;
      font-size: 32px;
      line-height: 40px;
      padding-right: 0.5em;
      word-break: break-word;
      font-family: $montserrat-font;
      font-weight: bold;
      margin: 0;
    }
  }

  &--city-year {
    .box-cities--link {
      color: $grey;
      font-size: 16px;
      font-weight: 700;
      line-height: 1em;
      font-family: $montserrat-font;
      padding-top: 16px;
    }
  }

  &--tags {
    padding: 0 5px 2px 5px;
    border-top: 1px solid rgba($grey, 0.3);
    height: 52px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @include respond-to('desktop') {
      padding: 2px 12px 4px 12px;
    }
    @include respond-to('tablet_more') {
      padding: 2px 12px 4px 12px;
      height: 63px;
    }

    .tags--tag {
      font-size: 9px;
      color: $blue;
      background: $violet-4;
      border: solid 1px $violet-4;
      margin: 4px;

      @include respond-to('mobile') {
        margin: 2px 0 1px 4px;
      }
      @include respond-to('desktop') {
        font-size: 10px;
      }
    }
  }
}
