.hp-map {
  background: $dark-grey;
  padding: 50px 16px 50px 0;
  color: $white;

  @include respond-to('tablet_more') {
    padding: 89px 23px 249px 23px;
  }
  @include respond-to('desktop') {
    padding: 81px 47px 128px 47px;
  }
  @include respond-to('hd') {
    padding: 81px 11px 114px 11px;
  }

  .cssmap-container {
    overflow: inherit;
    ul.cssmap-visible-list {
      width: auto !important;
      margin: 0 !important;
    }

    h1 {
      width: 100%;
      @include respond-to('desktop') {
        width: 50%;
        float: left;
      }
    }

    .country-map {
      @include respond-to('tabletandmobile') {
        float: none !important;
        margin-right: auto !important;
        margin-left: auto !important;
      }

      p {
        margin-bottom: 0;
        transition: 0.3s;
      }

      .region {
        display: block;
        overflow: hidden;
        max-width: 0;
        background: #fff;
        line-height: 32px;
        position: absolute;
        right: 32px;
        top: 0;
      }

      // paczka nie jest taka mądra
      // Zachodniopomorskie, Lubuskie, Dolnośląskie i Wielkopolskie.
      @include respond-to('phone') {
        .pl16, .pl4, .pl1, .pl15 {
          .region { right: unset; left: 32px; }
        }
      }

      .count {
        width: 32px;
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        font-family: $montserrat-font;
        font-weight: bold;
        color: $white;
        text-align: center;
        background: $grey;
      }

      li {
        a {
          padding: 0;
          text-shadow: 0 0 0 transparent;
          border: 0 solid;
          border-radius: 0;
          display: flex;
          background: transparent;
          white-space: nowrap;
          margin-top: -16px !important;
          margin-left: -16px !important;


          @include respond-to('phone') {
            display: none;
          }
        }

        &.active-region {
          a {
            z-index: 99;
            @include respond-to('phone') {
              display: block !important;
            }
          }

          .region {
            padding: 0 15px 0 15px;
            color: $blue;
            font-weight: bold;
            font-size: 16px;
            max-width: 300px;
          }

          .count {
            background: $blue;
            z-index: 1;
            @include respond-to('phone') {
              display: block !important;
            }
          }

        }
      }
    }

    .cssmap-visible-list {
      display: none;

      &-container {
        width: 296px;
      }

      .count {
        display: none;
      }

      .region {
        margin-bottom: 0;
      }

      li {
        line-height: 46px;
        border-bottom: 1px solid rgba($grey, 0.06);
        width: 296px;
      }

      a:hover {
        text-decoration: none;
      }
    }

    .list-of-cities {
      width: 100%;
      float: left;
      padding-right: 16px;
      box-sizing: border-box !important;
      @include respond-to('tablet_more') {
        padding-right: 83px;
        padding-left: 40px;
      }
      @include respond-to('desktop') {
        width: 436px;
        padding-left: 0;
        padding-right: 16px;
        position: relative;
        z-index: 1;
      }

      ul {
        padding-left: 0;
      }

      .list-of-city {
        //max-height: 336px;
        max-height: 230px;

        &__item {
          .list-of-city__link {
            line-height: 45px;
            width: 100%;
            border-bottom: 1px solid rgba($grey, 0.06);
            color: #fff;
            padding-left: 8px;
            box-sizing: border-box !important;
          }
        }
      }

      .list-of-region {
        &__select {
          font-size: 32px;
          line-height: 40px;
          font-family: $montserrat-font;
          font-weight: bold;
          margin: 40px 0 9px 0;
          padding-right: 20px;
          white-space: nowrap;
          cursor: pointer;
          position: relative;
          overflow: hidden;
          text-overflow: ellipsis;

          &:after {
            content: "";
            display: inline-block;
            position: absolute;
            width: 14px;
            height: 14px;
            right: 0;
            border: solid white;
            border-width: 0 4px 4px 0;
            transform: rotate(45deg);
            transition: 0.5s;
            margin-right: 4px;
            margin-top: 10px;
          }
        }

        &__item {
          &.clicked {
            .list-of-region__select:after {
              transform: rotate(-135deg) translateY(-100%) translateX(-50%);
              margin-right: 0px;
              margin-top: 0px;
            }

            .list-of-city {
              height: 100%;
            }
          }

          .list-of-city {
            height: 0;
          }
        }
      }
    }
  }

  &--link {
    padding-top: 39px;
    text-decoration: underline;
    @include respond-to('tablet_more') {
      padding-top: 55px;
      padding-left: 43px;
    }
  }

  .cssmap-loader {
    display: none;
  }
}
