.city-taxes-container {
  margin: 150px 0 0;
  flex-flow: row wrap;
  align-items: baseline;
  display: block;

  @include respond-to('tablet') {
    margin: 30px 0 0 0;
  }
  @include respond-to('phone') {
    margin: 20px 0 0;
    padding: 0;
  }

  &.w-100 {
    max-width: 100%;
    flex-flow: column;
    @include respond-to('hd') {
      padding-right: 17px;
      padding-left: 48px;
    }
  }

  .block-text {
    float: none;
    @include respond-to('tablet') {
      font-size: 18px;
      line-height: 1.33;
    }
    @include respond-to('phone') {
      font-size: 18px;
      line-height: 1.33;
      margin-bottom: 0;
    }

    &--position-fix {
      margin-top: 0;
    }

    & > div {
      @include respond-to('tablet') {
        float: none;
      }
    }

    .bold-blue {
      @include respond-to('tablet') {
        font-size: 40px;
        line-height: 1.3;
      }
    }

    &__subtitle {
      font-size: 32px;
      font-weight: bold;
      line-height: 1.25;
      color: #000000;
      margin: 60px 0 120px 0;
      @include respond-to('tablet') {
        margin: 25px 0 10px 0;
        font-size: 22px;
        line-height: 1.45;
      }

      @include respond-to('phone') {
        font-family: $montserrat-font;
        font-size: 22px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        color: #000000;
        margin: 40px 0 50px;
      }
    }
  }

  @import 'advanced-taxation-settings';
}

#city-taxes-calc {
  position: relative;

  .grey-spacer {
    @include respond-to('tablet') {
      margin-top: 10px;
    }
  }

  #calculationInfo {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}

@include respond-to('tablet') {
  .desktop-only {
    display: none;
  }
}

@include respond-to('phone') {
  .desktop-only {
    display: none;
  }
}

.box-budget__left__text__subtitle {
  font-family: $ubuntu-font;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  @include respond-to('tablet') {
    display: none;
  }
  @include respond-to('phone') {
    display: none;
  }
}

.taxes-for-mobile {
  display: none;

  @include respond-to('phone') {
    display: block;
    background: url('../../ncimp/img/main-img.png') no-repeat center center;
    width: 100%;
    height: 167px;
    background-size: contain;
    margin-bottom: 20px;
  }
}

.taxes-image-container {
  background: url('../../ncimp/img/main-img.png') no-repeat right center;
  display: flex;
  flex: 1;
  background-size: contain;
  width: 100%;
  @include respond-to('tablet') {
    background-position: center top;
    margin-top: 70px;
  }

  &__text-box {
    height: 624px;
    width: 43%;
    background-color: $blue;
    font-size: 64px;
    font-weight: bold;
    line-height: 1.25;
    letter-spacing: -1px;
    color: #ffffff;
    justify-content: center;
    flex-direction: column;
    padding-left: 109px;
    display: flex;

    br {
      @include respond-to('tablet') {
        display: none;
      }
    }

    @include respond-to('tablet') {
      margin-top: 340px;
      margin-bottom: 30px;
      height: auto;
      padding: 50px 20px;
      width: 100%;
      font-size: 48px;
      line-height: 1.25;
      letter-spacing: -0.8px;

    }
    @include respond-to('phone') {
      width: 100%;
      height: auto;
      font-family: $montserrat-font;
      font-size: 40px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: -0.6px;
      color: #ffffff;
      padding: 30px;
    }
  }
}

.taxes-dropdown {
  position: relative;
  margin-bottom: 24px;

  &.active {
    .taxes-dropdown__list {
      opacity: 1;
      visibility: visible;
    }

    i.togler {
      transform: rotate(180deg);
      top: 8px;
    }
  }

  &__text {
    font-family: $ubuntu-font;
    line-height: 1.45;
    font-size: 20px;
    display: flex;
    @include respond-to('phone') {
      font-size: 18px;
      display: inline-block;
    }

    span {
      font-weight: bold;
      color: #000000;
      margin-left: 7px;
      cursor: pointer;
    }

    i.togler {
      width: 30px;
      height: 20px;
      position: relative;
      margin-left: 8px;
      white-space: nowrap;
      display: inline-block;
      top: 0;
      transition: 0.5s;

      &:before {
        content: ' ';
        width: 14px;
        position: absolute;
        left: 4px;
        top: 14px;
        height: 3px;
        background: #000;
        transform: rotate(45deg);
      }

      &:after {
        content: ' ';
        width: 14px;
        position: absolute;
        right: 4px;
        top: 14px;
        height: 3px;
        background: #000;
        transform: rotate(-45deg);
      }
    }
  }

  &__list {
    position: absolute;
    list-style-type: none;
    z-index: 10;
    background-color: #fff;
    right: 0;
    top: 47px;
    border-radius: 2px;
    box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
    padding: 0 25px 8px 25px;
    visibility: hidden;
    opacity: 0;
    transition: visibility .3s, opacity .3s ease;
    @include respond-to('phone') {
      padding: 0 10px 8px 10px;
      top: 60px;
    }

    li {
      padding: 17px 0;
      font-family: $ubuntu-font;
      font-size: 22px;
      line-height: 32px;
      font-weight: bold;
      color: #b1b7c0;
      border-bottom: 1px solid #b1b7c0;
      transition: color .3s ease;
      cursor: pointer;
      @include respond-to('phone') {
        padding: 10px 0;
        font-size: 18px;
      }

      &:hover {
        color: #87a9fa;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.taxes-blue-box {
  display: flex;
  padding: 38px 18px;
  margin-top: 40px;
  background-color: $blue;
  position: relative;
  margin-bottom: 72px;

  @include respond-to('phone') {
    margin-left: -15px;
    width: calc(100% + 30px);
    padding: 15px
  }

  @include respond-to('tablet') {
    display: none;
  }

  &__text {
    font-family: $ubuntu-font;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
    padding-right: 250px;
    @include respond-to('phone') {
      font-size: 14px;
      line-height: 1.71;
      color: #ffffff;
      width: 48%;
      display: inline-block;
      padding-right: 1%;
    }
  }

  &__price {
    font-family: $montserrat-font;
    font-size: 56px;
    font-weight: bold;
    color: #ffffff;
    line-height: 1;
    width: 230px;
    position: absolute;
    right: 28px;
    top: 35px;
    text-align: right;
    white-space: nowrap;

    @include respond-to('phone') {
      position: relative;
      right: 0;
      top: 0;
      width: 48%;
      display: inline-block;
    }

    @include respond-to('phone') {
      font-size: 40px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}

.taxes-calculation-disclaimer {
  font-size: 13px;
  line-height: 20px;
  color: $black;
  margin: -60px 0 88px;
}

.taxes-modal-blue-slider {
  z-index: 10;
}

.taxes-boxes {
  position: relative;
}

.taxes-bottom-text {
  font-family: $ubuntu-font;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  margin-top: 75px;
  margin-bottom: 15px;
  @include respond-to('tablet') {
    margin-top: 50px;
  }
  @include respond-to('phone') {
    margin-top: 30px;
    margin-bottom: -15px;
  }

  a {
    text-decoration: underline;
  }
}

.taxes-boxes-wrapper {
  display: block;
  padding-bottom: 54px;
  position: relative;
  background: #FFF;
  width: auto;
  @include respond-to('tablet') {
    padding-bottom: 30px;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0 solid;
    border-radius: 0 !important;
    background-color: #000;
    color: #fff;
    line-height: 37px;
    text-align: center;
    font-size: 30px;
    margin-left: 2px;

    &:focus {
      box-shadow: 0 0 0 0rem rgba($white, 0);
    }

    &.slick-prev, &.slick-next {
      &:after {
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -8px;
        // bazowo powinno być -6px ale wizualnie trzeba lekko przesunąć środek
        margin-left: -7px;
        width: 12px;
        height: 17px;
      }
    }

    &.slick-prev {
      position: absolute;
      right: 42px;
      bottom: 0;
      @include respond-to('tablet') {
        right: 45px;
      }

      &:after {
        background: url('../../ncimp/img/slick-arrow-prev.png') center no-repeat;
        background-size: contain;
      }
    }

    &.slick-next {
      position: absolute;
      right: 0;
      bottom: 0;
      color: transparent;

      &:after {
        background: url('../../ncimp/img/slick-arrow-prev.png') center no-repeat;
        background-size: contain;
        transform: rotate(180deg);
        margin-left: -5px;
      }
    }
  }

  .slick-dots {
    list-style-type: none;
    padding: 0;
    width: 100%;
    display: flex;
    @include respond-to('tablet') {
      display: none !important;
    }

    li {
      flex: 1;
      color: transparent;

      &.slick-active {
        button {
          background-color: $blue;
        }
      }

      button {
        font-size: 0;
        width: 100%;
        display: block;
        padding: 0;
        height: 2px;
        background-color: rgba(177, 183, 192, 0.3);
        transition: background-color .3s ease;

        &:hover {
          background-color: #73ADFF;
        }
      }
    }
  }
}

.tax-slider-counter {
  position: absolute;
  right: 97px;
  bottom: 10px;
  font-family: $montserrat-font;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  @include respond-to('tablet') {
    right: 100px;
    bottom: 4px;
  }
}

.tax-small-box {
  margin-bottom: 30px;
  width: 30%;
  float: left;
  position: relative;
  padding-right: 20px;
  display: flex !important;
  flex-direction: column;
  @include respond-to('tablet') {
    margin-bottom: 45px;
    padding-right: 50px;
    padding-left: 50px;
  }
  @include respond-to('phone') {
    float: none;
    padding-left: 9%;
  }

  .pop-over {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    .info-ico {
      display: none;
      @include respond-to('desktop') {
        display: block;
      }
    }
  }

  &:hover {
    .tax-small-box__image {
      img {
        transform: scale(1.1);
      }
    }

    .tax-small-box__amount {
      color: #000;
    }
  }

  &__image {
    width: 176px;
    height: 112px;
    cursor: pointer;
    margin-bottom: 14px;

    img {
      max-width: 176px;
      max-height: 112px;
      transform: scale(1);
      transition: transform .3s cubic-bezier(.21, .6, .44, 1);
    }

    @include respond-to('tablet') {
      width: 132px;
      height: 82px;
      img {
        max-width: 132px;
        max-height: 82px;
      }
    }

    @include respond-to('phone') {
      width: 132px;
      height: 82px;
      padding-right: 10px;

      img {
        max-width: 132px;
        max-height: 82px;
      }
    }
  }

  &__title {
    font-family: $ubuntu-font;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    overflow: hidden;
    margin-bottom: auto;
    @include respond-to('tablet') {
      margin-top: 8px;
      line-height: 1.7;
      font-size: 14px;
      height: 46px
    }
    @include respond-to('phone') {
      padding-right: 10px;
      font-size: 14px;
      line-height: 1.7;
      height: 69px;
    }

  }

  &__amount {
    font-family: $montserrat-font;
    font-size: 32px;
    font-weight: bold;
    color: $blue;
    transition: 0.3s;
    @include respond-to('tablet') {
      font-size: 22px;
      margin-bottom: 5px;
    }
    @include respond-to('phone') {
      font-size: 22px;
      margin-bottom: 5px;
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 1.38;
    color: #000000;
    @include respond-to('tablet') {
      margin-bottom: 5px;
      line-height: 1.6;
      font-size: 11px;
    }
    @include respond-to('phone') {
      margin-bottom: 5px;
      line-height: 1.6;
      font-size: 11px;
    }

  }

  &__total-cost {
    font-family: $montserrat-font;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    @include respond-to('tablet') {
      line-height: 1.15;
      font-size: 14px;
    }
    @include respond-to('phone') {
      line-height: 1.15;
      font-size: 14px;
      padding-right: 10px;
    }
  }
}

.custom-range-input {
  padding-top: 75px;
  position: relative;

  span {
    font-family: $montserrat-font;
    font-size: 64px;
    font-weight: bold;
    letter-spacing: -1px;
    color: $blue;
    position: absolute;
    left: 0;
    top: 0;

    &:after {
      content: 'zł';
      right: 0;
      bottom: 0;
      margin-left: 13px;
    }
  }

  input[type=range] {
    height: 54px;
    -webkit-appearance: none;
    width: 100%;
    padding: 0;
  }

  input[type=range]:focus {
    //outline: none;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #d7dade;
    border-radius: 1px;
    border: 0px solid #D7DADE;
  }

  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 10px solid #0564FA;
    height: 36px;
    width: 36px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -18px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #d7dade;
  }

  input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 0px 0px 0px #000000;
    background: #d7dade;
    border-radius: 1px;
    border: 0px solid #D7DADE;
  }

  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000031;
    border: 10px solid $blue;
    height: 36px;
    width: 36px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
  }

  input[type=range]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type=range]::-ms-fill-lower {
    background: #d7dade;
    border: 0px solid #D7DADE;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }

  input[type=range]::-ms-fill-upper {
    background: #d7dade;
    border: 0px solid #D7DADE;
    border-radius: 2px;
    box-shadow: 0px 0px 0px #000000;
  }

  input[type=range]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 0px 0px 0px #000031;
    border: 10px solid $blue;
    height: 36px;
    width: 36px;
    border-radius: 50px;
    background: #FFFFFF;
    cursor: pointer;
  }

  input[type=range]:focus::-ms-fill-lower {
    background: #d7dade;
  }

  input[type=range]:focus::-ms-fill-upper {
    background: #d7dade;
  }
}

.taxes-modal-slider {
  // top jest bardzo okreslony, slider ma rozną wysokość i to jest problem
  .slick-prev, .slick-next {
    position: absolute;
    font-size: 0;
    left: 0;
    height: 40px;
    width: 40px;
    padding: 0;
    z-index: 10000;
    background: #000;
    top: 110px;

    @include respond-to('tablet') {
      top: 85px;
    }

    @include respond-to('phone') {
      top: auto;
      left: 20px;
      bottom: 75px;
      transform: translateY(0);
    }
  }

  .slick-prev {
    @include respond-to('phone') {
      top: 587px;
    }

    &:after {
      content: ' ';
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 14px;
      transform: rotate(135deg);
      position: absolute;
      top: 0;
      right: 11px;
    }
  }

  .slick-next {
    left: auto;
    right: 0;

    @include respond-to('phone') {
      right: 20px;
      top: 587px;
    }

    &:after {
      content: ' ';
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      margin-top: 14px;
      transform: rotate(-45deg);
      position: absolute;
      top: 0;
      left: 11px;
    }
  }

  &__separator {
    height: 2px;
    opacity: 0.3;
    background-color: #b1b7c0;
    position: absolute;
    bottom: 49px;
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
    max-width: 600px;

    @include respond-to('phone') {
      display: none;
    }
  }

  &__top-white {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 70px;

    @include respond-to('phone') {
      flex-wrap: wrap;
    }

    &__next-slide-label {
      // top jest bardzo dobrze okreslony, inne próby implementacji nie działają bo height jest zmienny

      font-family: $ubuntu-font;
      top: 110px;
      right: 40px;
      position: absolute;
      font-size: 10px;
      line-height: 1.3;
      text-align: right;
      padding: 5px 10px;
      color: #ffffff;
      background-color: #b1b7c0;
      height: 40px;
      display: flex;
      align-items: center;

      @include respond-to('tablet') {
        top: 85px;
        max-width: 100px;
      }

      @include respond-to('phone') {
        top: 587px;
        right: 60px;
        max-width: 200px;
      }
    }

    &__image {
      width: 278px;
      height: 177px;
      position: relative;
      margin-right: 50px;
      display: inline-block;
      vertical-align: top;

      @include respond-to('phone') {
        margin: 0 auto 95px auto;
        display: block;
        width: 100%;

        &:after {
          content: ' ';
          height: 2px;
          background: rgba(#b1b7c0, 0.3);
          width: calc(100% - 40px);
          margin-left: 20px;
          position: absolute;
          bottom: -18px;
          left: 0;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__text {
      display: inline-block;
      vertical-align: top;
      width: 600px - 50px - 278px - 10px;

      @include respond-to('phone') {
        margin: 0 20px;
        padding-bottom: 100px;
      }

      &__title {
        font-family: $montserrat-font;
        font-size: 32px;
        font-weight: bold;
        line-height: 1.25;
        color: #000000;
      }

      &__subtitle {
        font-family: $montserrat-font;
        font-size: 32px;
        font-weight: bold;
        color: $blue;
      }

      &__label {
        font-family: $ubuntu-font;
        font-size: 13px;
        line-height: 1.38;
        color: #000000;
        margin-bottom: 6px;
      }

      &__amount {
        font-family: $montserrat-font;
        font-size: 20px;
        font-weight: bold;
        line-height: 1;
        color: #000000;
        margin-bottom: 15px;
      }
    }
  }

  &__bottom-blue {
    background-color: $blue;
  }
}

.taxes-subgroup-tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: calc(100% + 100px);
  bottom: calc(100% + 10px);
  left: -50px;
  right: 0;
  margin: 0 auto;
  background: #fff;
  padding: 30px 40px;
  transition: visibility .3s, opacity .3s ease;
  box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
  pointer-events: none;

  p {
    margin-bottom: 0;
  }

  &:before {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #fff;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__spends {
    display: flex;
    flex-wrap: wrap;

    &.show-separator {
      border-bottom: 1px solid #b1b7c0;
      margin-bottom: 18px;
      padding-bottom: 18px;
    }

    &__label {
      font-family: $ubuntu-font;
      font-size: 16px;
      line-height: 1.5;
      color: #000000;
    }

    &__amount {
      font-family: $montserrat-font;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.5;
      text-align: right;
      color: #000000;
      margin-left: auto;
    }
  }

  &__description {
    font-family: $ubuntu-font;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
  }
}

.taxes-bottom-groups-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 60px 80px;
  grid-gap: 33px 50px;

  @include respond-to('tablet') {
    grid-template-columns: 1fr 1fr;
    padding: 50px;
  }
  @include respond-to('phone') {
    padding: 30px 20px;
    display: block !important;
  }

  .empty-text {
    font-family: $ubuntu-font;
    font-size: 32px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.5);
  }
}

.taxes-modal-slider-bottom-group {
  @include respond-to('phone') {
    margin-bottom: 33px;
    max-width: 275px;
  }
  position: relative;

  &.has-tooltip {
    &:hover,
    &.active {
      .taxes-modal-slider-bottom-group__title,
      .taxes-modal-slider-bottom-group__subtitle,
      .taxes-modal-slider-bottom-group__label,
      .taxes-modal-slider-bottom-group__amount {
        color: #000;
        cursor: pointer;
        @include respond-to('tabletandmobile') {
          color: #ffffff;
        }
      }
    }
  }

  &__title {
    font-family: $ubuntu-font;
    font-size: 16px;
    line-height: 1.5;
    color: #ffffff;
    transition: color .3s ease;
  }

  &__subtitle {
    font-family: $montserrat-font;
    font-size: 32px;
    font-weight: bold;
    color: #ffffff;
    transition: color .3s ease;
  }

  &__label {
    font-family: $ubuntu-font;
    font-size: 13px;
    line-height: 1.38;
    color: #ffffff;
    transition: color .3s ease;
  }

  &__amount {
    font-family: $montserrat-font;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #ffffff;
    transition: color .3s ease;
  }
}

.taxes-modal {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: visibitlity .3s, opacity .3s ease;
  padding: 0;
  @include respond-to('phone') {
    overflow: scroll;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__scroll-container {
    position: absolute;
    height: 100%;
    width: 100%;

  }

  &__inner {
    margin: 50px auto;
    position: relative;
    border-radius: 2px;
    background: #ffffff;
    padding: 0;
    box-shadow: 0 0 30px rgba(51, 57, 75, 0.2);
  }

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &.fund-solecki {
      background: #fff;
    }

    &--side-padding {
      padding: 0 38px;
      @include respond-to('phone') {
        padding: 0;
      }
    }

    &--sizer {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      .slick-list.draggable {
        overflow: visible !important;
      }

    }

    &__title {
      padding: 50px 0 30px 0;
      font-family: $montserrat-font;
      font-size: 56px;
      font-weight: bold;
      line-height: 1.4;
      letter-spacing: -0.6px;
      color: #000000;
      display: inline-block;
      @include respond-to('tablet') {
        margin-left: 20px;
        max-width: 350px;
        display: block;
        font-size: 40px;
        line-height: 1.2;
      }
      @include respond-to('phone') {
        padding: 50px 70px 30px 20px;
        font-size: 40px;
        line-height: 1.2;
        display: block;
        width: 350px;
      }
    }

    &__subtitle {
      display: inline-block;
      margin-left: 50px;
      font-family: $ubuntu-font;
      font-size: 16px;
      line-height: 1.5;
      color: #000000;
      max-width: 425px;
      margin-bottom: 72px;
      @include respond-to('tablet') {
        margin-left: 20px;
        max-width: 300px;
      }
      @include respond-to('phone') {
        margin-bottom: 40px;
        margin-left: 20px;
      }

      span {
        font-weight: bold;
      }
    }
  }

  &__close {
    width: 64px;
    height: 64px;
    position: absolute;
    z-index: 10000;
    top: 30px;
    right: 30px;
    background: rgba(177, 183, 192, 0);
    cursor: pointer;
    transition: background-color .3s ease;
    @include respond-to('tablet') {
      top: 20px;
      right: 20px;
    }
    @include respond-to('phone') {
      top: 12px;
      right: 12px;
    }

    &:hover {
      background: rgba(177, 183, 192, 0.1);

    }

    &:before {
      content: ' ';
      background: #b1b7c0;
      height: 2px;
      width: 40px;
      position: absolute;
      left: 12px;
      top: 32px;
      transform: rotate(45deg);
      transform-origin: center;
    }

    &:after {
      content: ' ';
      background: #b1b7c0;
      height: 2px;
      width: 40px;
      position: absolute;
      left: 12px;
      top: 32px;
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }
}

.slick-slide > div {
  display: flex;
}
