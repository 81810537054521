.city-incomes {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 64px 16px;
  position: relative;
  @include respond-to('desktop') {
    padding: 84px 2px 0 3px;
  }
  @include respond-to('phone') {
    padding-top: 0;
    padding-bottom: 0;
  }

  .city-incomes {
    @include respond-to('phone') {
      padding: 0;
      width: 100%;
    }
  }

  .block-text {
    &--break-income-pit {
      max-width: 380px;
      @include respond-to('phone') {

      }
      @include respond-to('tablet') {
        max-width: unset;
      }
    }

    &.mt-65 {
      margin-top: 30px;
    }
  }

  .box-budget__right {

    .type-budget-info {
      display: none;
      font-family: $ubuntu-font;
      font-size: 11px;

      @include respond-to('tablet') {
        display: block;
        margin-top: 40px;
        margin-bottom: 20px;
      }

      @include respond-to('phone') {
        display: none;
        margin-top: 60px;
      }
    }

    .type-budget-boxes {
      width: 100%;
      @include respond-to('phone') {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
      }

      &__box {
        width: 272px;
        height: 224px;
        float: left;
        margin-top: 70px;

        @include respond-to('tablet') {
          width: 33%;
          padding-right: 30px;
          word-break: break-word;
          margin-top: 40px;
        }

        @include respond-to('phone') {
          width: calc(50% - 20px);
          float: left;
          margin-top: 15px;
        }

        &:nth-of-type(odd) {
          margin-right: 84px;
          @include respond-to('phone') {
            margin-right: 40px;
          }
          @include respond-to('tablet') {
            margin-right: 0;
          }

        }

        &__image {
          width: 224px;
          height: 112px;
          position: relative;

          img {
            max-height: 112px;
            max-width: 224px;
          }

          @include respond-to('tablet') {
            width: 157px;
            height: 88px;

            img {
              max-height: 88px;
              max-width: 157px;
            }
          }

          @include respond-to('phone') {
            width: 147px;
            height: 88px;
            margin-left: -8px;

            img {
              max-height: 82px;
              max-width: 147px;
            }
          }

          .pop-over {
            position: absolute;
            left: 0;
            top: 0;

            .info-ico {
              display: none;
              @include respond-to('desktop') {
                display: block;
              }
            }
          }
        }

        &__title {
          font-family: $ubuntu-font;
          font-size: 16px;
          line-height: 1.5;
          color: #000000;
          margin-top: 5px;

          @include respond-to('tablet') {
            margin-top: 15px;
            font-size: 12px;
            line-height: 2;
          }
          @include respond-to('phone') {
            font-size: 12px;
            line-height: 2;
          }
        }

        &__bold-blue {
          font-family: $montserrat-font;
          font-size: 32px;
          font-weight: bold;
          margin-top: 5px;
          margin-bottom: 5px;
          color: $blue;
          transition: 0.3s;

          @include respond-to('tablet') {
            font-size: 16px;
            margin-top: 10px;
          }
          @include respond-to('phone') {
            font-size: 16px;
          }
        }

        &__bold-percent {
          font-family: $montserrat-font;
          font-size: 16px;
          font-weight: bold;
          line-height: 1;
          color: #000000;

          @include respond-to('tablet') {
            font-size: 12px;
            line-height: 1.33;
            margin-top: 8px;
          }
          @include respond-to('phone') {
            font-size: 12px;
            line-height: 1.33;
          }
        }
      }
    }

    .describe {
      line-height: 24px;
      font-family: $ubuntu-font;
      font-size: 16px;
      color: #000000;
      float: left;
      margin-top: 60px;
      margin-bottom: 40px;

      @include respond-to('phone') {
        margin: 0;
      }
    }
  }
}

.mt-20 {
  margin-top: 20px;

  @include respond-to('phone') {
    margin-top: 10px;
  }
}

.mt-65 {
  margin-top: 65px;
  @include respond-to('phone') {
    margin-top: 0;
  }
}
