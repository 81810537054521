.budgets-list {
  .separator {
    min-width: 2px;
    max-width: 2px;
    height: 27px;
    margin: 0 24px;
    background-color: #eef1f8;
    display: none;
    @include respond-to(desktop) {
      display: block;
    }
  }

  .intro {
    background-color: $white;
    padding: 21px 0 0;
    @include respond-to(desktop) {
      padding-top: 65px;
    }

    h1 {
      text-align: center;
      margin-bottom: 26px;
      line-height: normal;
      letter-spacing: -1.67px;
      @include respond-to(desktop) {
        line-height: 1.25;
        letter-spacing: -1.03px;
        margin-bottom: 85px;
      }
    }

    @import 'budgets-list-location-selector';
    @import 'budgets-list-filter';
  }

  .cities {
    background-color: rgba(238, 241, 247, 0.6);

    .list-cities {
      display: block !important;
      @include respond-to(desktop) {
        padding-top: 10px;
      }

      .box-city {
        padding-bottom: 40px;

        .row-budget {
          display: block;
          border-radius: 10px;
          box-shadow: 0 0 10px 0 rgba(177, 183, 192, 0.3);
          background-color: $white;
          padding: 20px 12px 12px;
          box-sizing: border-box;
          margin: 7px 0;
          @include respond-to(desktop) {
            display: flex;
            padding: 20px 24px;
          }

          .part {
            &:first-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 15px;
              border-bottom: 1px solid #eef1f8;
              margin-bottom: 6px;
              @include respond-to(desktop) {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                min-width: 500px;
                max-width: 500px;
              }
            }

            &:last-child {
              min-height: 50px;
              @include respond-to(desktop) {
                min-height: auto;
                padding-left: 50px;
              }
            }
          }

          .location, .year {
            font-family: $montserrat-font;
            font-size: 18px;
            font-weight: bold;
            color: $black;
            @include respond-to(desktop) {
              font-size: 24px;
            }
          }

          .location {
            @include respond-to(desktop) {
              flex: 1;
            }
          }

          .year {
            color: $grey;
            @include respond-to(desktop) {
              min-width: 75px;
              max-width: 75px;
              text-align: center;
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: right;

            li {
              display: inline-block;
              font-size: 10px;
              line-height: 18px;
              font-weight: bold;
              color: $blue;
              background-color: $violet-4;
              border-radius: 22.5px;
              padding: 0 8px;
              margin: 2px;
            }
          }
        }
      }
    }
  }
}
