.disclaimer {
  padding-top: 32px;
  padding-bottom: 32px;
  font-size: 14px;
  line-height: 20px;
  max-width: 900px;
  margin: 0 auto;
  @include respond-to(tablet_more) {
    padding-top: 64px;
    padding-bottom: 64px;
    font-size: 16px;
    line-height: 24px;
  }
}

.divider {
  height: 7px;
  background-color: rgba(#b1b7c0, .3);
}
