.city-fund-solecki {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 0 16px;

  .slide-caption {
    p {
      @include respond-to('phone') {
        margin-bottom: 0;
      }
    }
  }

  .sticky-column-left {
    padding-bottom: 50px;
    @include respond-to('tablet') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
    @include respond-to('hd') {
      padding-bottom: 130px;
      max-width: calc(1140px * 0.43);
      margin-left: calc(50% - (1140px / 2));
    }
  }

  .column-right {
    @include respond-to('desktop') {
      position: relative;
      top: 79px;
      margin-bottom: 84px;
    }
    @include respond-to('hd') {
      width: calc(1140px * 0.57 + 50% - (1140px / 2));
    }
  }

  .number-blue {
    display: inline-block;
    width: 40px;
    background: $blue;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 26px;
    @include respond-to('desktop') {
      margin-bottom: 2px;
    }
  }

  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.77px;
    color: #000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet') {
      font-size: 40px;
      line-height: 48px;
    }
    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }

    p {
      margin-bottom: 0;
      @include respond-to('desktop') {
        margin-left: -2px;
      }
    }
  }

  &__tekst-right {
    font-size: 18px;
    line-height: 24px;
    color: #000;
    @include respond-to('tablet') {
      line-height: 32px;
    }
    @include respond-to('desktop') {
      font-size: 22px;
      line-height: 32px;
    }
    @include respond-to('hd') {
      width: calc(1140px * 0.57);
      display: inline-block;
      padding-right: 10px;
    }

    b, strong {
      font-family: $ubuntu-font;
      font-weight: bold;
    }
  }

  .grey-spacer {
    margin-top: 48px;
    @include respond-to('tablet') {
      margin-top: 34px;
    }
    @include respond-to('hd') {
      margin-bottom: 85px;
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
