.spinner {
  width: 100%;
  text-align: center;
  padding-bottom: 90px;
  transform: rotateY(180deg);
  overflow: hidden;
  position: relative;
  //top: 50%;
  z-index: 1;
  &.visibility{
    display: none;
  }
  .loader {
    font-size: 10px;
    margin: 0 auto;
    text-indent: -9999em;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $blue;
    background: linear-gradient(to right, $blue 10%, rgba($blue, 0) 42%);
    position: relative;
    animation: load3 1.4s infinite linear;
    transform: translateZ(0);
    &:before {
      width: 50%;
      height: 50%;
      background: $blue;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
    &:after {
      background-color: #f5f6fa;
      width: 60%;
      height: 60%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




