.sitemap {
  margin-bottom: 95px;
  @include respond-to(tablet_more) {
    margin-bottom: 120px;
  }

  h1 {
    margin: 44px 0 125px;
    max-width: 205px;
    @include respond-to(tablet_more) {
      margin: 135px 0 185px;
      max-width: none;
    }
  }

  .section {
    a {
      font-family: $montserrat-font;
      font-weight: bold;
    }

    > a {
      font-size: 22px;
      line-height: 32px;
      color: #000;
      display: inline-block;
      margin-bottom: 20px;
      @include respond-to(tablet_more) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 24px;
      }

      &:hover {
        color: $blue;
      }

      h2 {
        font-size: inherit;
        line-height: inherit;
      }
    }

    .sub-pages {
      margin-bottom: 36px;
      @include respond-to(desktop) {
        columns: 2;
      }

      a {
        font-size: 14px;
        line-height: 16px;
        @include respond-to(tablet_more) {
          font-size: 16px;
        }
      }

      .sub-page {
        margin-bottom: 16px;
        break-inside: avoid-column;

        &:last-child {
          margin-bottom: 0;
        }

        > a {
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .second-level {
          list-style: none;
          margin: 0;
          padding: 0 0 0 20px;

          li {
            margin-bottom: 10px;
            line-height: 16px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &.one-level > a {
          margin-bottom: 0;
        }
      }
    }
  }
}
