.demo-intro {
  padding: 84px 16px 64px 16px;
  position: relative;
  @include respond-to(tablet_more) {
    padding: 162px 2px 75px 3px;
    min-height: 550px;
  }

  .demo-title {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    @include respond-to(tablet_more) {
      font-size: 64px;
      line-height: 1.25;
      letter-spacing: -1px;
      color: #000000;
    }
  }

  .location-logo {
    max-width: 100%;
    height: auto;
    @include respond-to(tablet_more) {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }
  }
}
