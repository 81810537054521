h1, h2, h3 {
  font-family: $montserrat-font;
  font-weight: bold;
  margin: 0;
}
h1 {
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -0.8px;
  @include respond-to('desktop') {
    font-size: 64px;
    line-height: 80px;
    letter-spacing: -1px;
  }
}
h2 {
  font-size: 40px;
  @include respond-to('desktop') {
    font-size: 56px;
  }
}
h3 {
  font-size: 22px;
  line-height: 32px;
  @include respond-to('desktop') {
    font-size: 32px;
    line-height: 40px;
  }
}
