.hp-wrote {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 0 16px;
  @include respond-to('phone') {
    max-width: 100%;
  }
  @include respond-to('tablet_more') {
    padding: 110px 26px 0 26px;
  }
  @include respond-to('desktop') {
    padding: 72px 10px 0 10px;
    max-width: 1140px;
  }

  .sticky-column-left {
    padding-bottom: 40px;
    @include respond-to('tablet_more') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
  }

  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.77px;
    color: #000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet_more') {
      font-size: 48px;
      line-height: 56px;
    }
    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }
  }

  .list-wrote {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    @include respond-to('desktop') {
      margin-bottom: 33px;
    }
    @include respond-to('hd') {
      margin-bottom: 78px;
    }

    &__logo {
      text-align: center;
      padding: 0;
      margin-bottom: 59px;
      @include respond-to('tablet_more') {
        text-align: left;
        margin-bottom: 112px;
      }

      img {
        transition: 0.5s;
        height: 65px;
        @include respond-to('tablet_more') {
          height: 78px;
        }
      }
    }
  }
}
