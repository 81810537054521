.tag {
  &-title {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: normal;
    color: $grey;
    white-space: nowrap;
    margin-right: 5px;
    padding-top: 1px;
  }
  .tags {
    width: auto;
    margin-bottom: 34px;
    @include respond-to('tablet_more') {
      margin-bottom: 8px;
    }
    @include respond-to('desktop') {
      margin-bottom: 0;
    }
    @include respond-to('phone') {
      margin-bottom: 29px;
    }
  }
  .one-line {
    display: flex;
    flex-wrap: wrap;
    //align-items: center;
    @include respond-to('tablet_more') {
      max-width: 57%;
      flex-wrap: nowrap;
    }
    @include respond-to('desktop') {
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }
}

.tags--tag {
  color: $grey;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
  background: transparent;
  padding: 0 8px;
  margin: 5px 5px 5px 0;
  border-radius: 22.5px;
  border: solid 1px $grey;
  vertical-align: middle;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  height: auto;
  width: auto;
  text-transform: none;
  justify-content: normal;
  &:hover {
    color: $grey;
    background: transparent !important;
    border: solid 1px $grey;
  }
  &.active {
    color: $blue;
    background: $violet-4 !important;
    border: solid 1px $violet-4;
  }

  @include respond-to('desktop') {
    margin: 4px 0 4px 13px;
  }
}

.tag {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
}
.notmobile {
  .tags--tag {
    &:hover {
      color: $blue;
      background: $violet-4 !important;
      border: solid 1px $violet-4;
    }
  }
}
