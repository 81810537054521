.search-box {
  width: 100%;
  margin: 0 auto 69px;
  position: relative;
  z-index: 2;
  @include respond-to(desktop) {
    width: 544px;
    display: grid;
    grid-template-columns: 1fr 200px;
    margin-bottom: 110px;
  }

  .selection {
    font-size: 16px;
    font-weight: bold;
    color: $grey;
    padding: 24px 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    position: relative;
    z-index: 2;
    border: 1px solid $grey;
    display: block;
    @include respond-to(desktop) {
      padding: 20px 26px;
    }

    &::after {
      content: "";
      position: absolute;
      border-right: .25em solid currentColor;
      border-bottom: .25em solid currentColor;
      transform: rotate(45deg);
      right: 1em;
      top: calc(50% - .55em);
      box-sizing: border-box;
      width: .9em;
      height: .9em;
    }
  }

  .button {
    position: relative;
    z-index: 2;
    width: 100%;
    background-color: $grey;
  }

  .dropdown-container {
    display: none;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
  }

  .selection.show-dropdown ~ .dropdown-container {
    display: block;
  }

  .back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .dropdown {
    border-radius: 2px;
    box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
    background-color: white;
    border: none;
    padding: 24px;
    position: absolute;
    z-index: 1;
    top: 0;
    width: 100%;
    @include respond-to(desktop) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .search-field {
      font-size: 36px;
      line-height: 50px;
      font-weight: bold;
      color: $blue;
      text-align: center;
      padding: 0;
      margin: 21px 0 52px;
      cursor: text;
      position: relative;
      height: 50px;
      width: 100%;

      .input-text-container,
      .hidden-label {
        display: inline-block;
        font: inherit;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        cursor: text;
      }

      .hidden-label {
        opacity: 0;
        color: $grey;
        z-index: 2;
      }

      .input-text-container {
        display: flex;
        justify-content: center;
      }

      input.search-input {
        color: $blue;
        background-color: transparent;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0;
        text-align: center;
        font: inherit;
        margin: 0;
        position: relative;
        z-index: 3;

        &:not(:focus) ~ .hidden-label:not(:active) {
          opacity: 1;
        }
      }

      .input-text:not(:empty) ~ .input-text-container .hidden-label:not(:active) {
        opacity: 0;
      }

      /* clears the 'X' from Internet Explorer */
      input[type=search]::-ms-clear,
      input[type=search]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      /* clears the 'X' from Chrome */
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display: none;
      }

      .hint-suffix {
        position: relative;
        z-index: 1;
      }

      @keyframes blink {
        0% {
          border-right: 2px solid transparent;
        }
        60% {
          border-right: 2px solid transparent;
        }
        61% {
          border-right: 2px solid currentColor;
        }
        100% {
          border-right: 2px solid currentColor;
        }
      }

      .input-text {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
      }

      .input-text-container {
        color: $grey;

        .input-text {
          color: $blue;
          animation: blink 1s steps(3, start) infinite alternate;
        }
      }
    }

    .results {
      list-style: none;
      padding: 0 36px 0 0;
      margin: 0;
      max-height: 234px;
      overflow: auto;

      .result-item {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        min-height: 34px;
        padding: 4px 0;
        color: $grey;
        cursor: pointer;

        &:hover {
          color: $blue;
        }

        + .result-item {
          border-top: 1px solid rgba($grey, .2);
        }

        .match {
          background-color: transparent;
          color: $blue;
          padding: 0;
          margin: 0;
          display: inline;
        }
      }
    }
  }
}
