.request-form {
  padding: 65px 0 95px;

  h2 {
    margin-bottom: 30px;
    @include respond-to(tablet_more) {
      margin-top: 60px;
      margin-bottom: 35px;
      max-width: 430px;
    }
  }

  p {
    @extend h3;
    color: $blue;
    margin-bottom: 40px;
  }

  .contact-form-terms {
    margin-bottom: 10px;
    @include respond-to(tablet_more) {
      margin-bottom: 20px;
    }
  }

  .contact-form-marketing {
    margin-bottom: 30px;
  }
}
