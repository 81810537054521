.budgets-city {
  .city-expanses {
    .block-text {
      @include respond-to('phone') {
        margin-top: -3px;
        margin-bottom: 0;
      }

      .limit-width {
        @include respond-to('desktop') {
          width: 446px;
        }
      }

      &.mt-65 {
        @include respond-to('phone') {
          margin-top: 30px;
        }

      }
    }
  }
}

.city-expanses {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 64px 16px;
  position: relative;
  @include respond-to('phone') {
    padding-top: 25px;
    padding-bottom: 30px;
  }

  .progress-percent {
    float: left;
    width: 100%;
    margin-top: 50px;
    padding-bottom: 152px;
    @include respond-to('tablet') {
      max-height: 550px;
      padding-bottom: 79px;
    }
    @include respond-to('phone') {
      max-height: 490px;
      padding: 0;
      margin-bottom: 88px;
      margin-top: 20px;
    }
    @include respond-to('mobile') {
      height: 100vh;
    }

    &__left, &__right {
      float: left;
      height: 256px;

      @include respond-to('mobile') {
        height: 100%;
        width: 100%;
      }

      &__text {
        padding-top: 38px;
        padding-left: 55px;

        @include respond-to('phone') {
          padding-top: 16px;
          padding-left: 16px;
        }

        @include respond-to('tablet') {
          padding-top: 20px;
          padding-left: 25px;
        }

        &--topic {
          font-family: $montserrat-font;
          font-size: 16px;
          font-weight: bold;
          line-height: 28px;
          color: #ffffff;

          @include respond-to('phone') {
            font-size: 14px;
            line-height: 1.71;
          }
        }

        &--percent {
          font-family: $montserrat-font;
          font-size: 64px;
          font-weight: bold;
          line-height: 1.25;
          letter-spacing: -1px;
          margin-top: 10px;
          margin-bottom: 10px;
          color: #ffffff;
          @include respond-to('tablet') {
            font-size: 48px;
            line-height: 48px;
            margin: 5px 0 0 0;
          }

          @include respond-to('phone') {
            font-size: 40px;
            line-height: 1.2;
            letter-spacing: -0.6px;
            color: #ffffff;
            margin: 0;
          }
        }

        &--value {
          font-family: $montserrat-font;
          font-size: 32px;
          font-weight: bold;
          color: #ffffff;
          @include respond-to('tablet') {
            font-size: 22px;
          }

          @include respond-to('phone') {
            font-size: 22px;
          }
        }
      }
    }

    &__left {
      background: #5897fb;
      max-width: 70%;
      min-width: 30%;
      @include respond-to('phone') {
        max-width: unset;
        min-width: unset;
        max-height: 65%;
        min-height: 35%;
      }
      @include respond-to('tablet') {
        max-width: unset;
        min-width: unset;
        max-height: 65%;
        min-height: 35%;
      }

    }

    &__right {
      background: $blue;
      min-width: 30%;
      max-width: 70%;
      @include respond-to('phone') {
        min-width: unset;
        max-width: unset;
        max-height: 65%;
        min-height: 35%;
        display: flex;
        align-items: center;
      }
      @include respond-to('tablet') {
        min-width: unset;
        max-width: unset;
        max-height: 65%;
        min-height: 35%;
      }

      .progress-percent__right__text {
        @include respond-to('phone') {
          padding-top: 0;
        }
      }
    }

  }

  .bold-blue {
    @include respond-to('phone') {
      margin-top: 3px;
    }
  }
}

.is-demo {
  .city-expanses {
    margin-bottom: 40px;
    @include respond-to('desktop') {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}
