.bar-chart-wrapper {

  @include respond-to('phone') {
    max-height: 360px;
  }
}

.city-forecast {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 0 16px;
  position: relative;
  @include respond-to('phone') {
    max-width: 100%;
  }
  @include respond-to('tablet_more') {
    padding: 110px 26px 0 26px;
    max-width: 100%;
  }
  @include respond-to('desktop') {
    padding: 143px 10px 0 10px;
    max-width: 1140px;
  }

  .sticky-column-left {
    padding-bottom: 50px;
    @include respond-to('tablet_more') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
    @include respond-to('hd') {
      padding-bottom: 130px;
    }
  }

  .column-right {
    @include respond-to('desktop') {
      position: relative;
      top: 79px;
      margin-bottom: 63px;
    }
  }

  .number-blue {
    display: inline-block;
    width: 40px;
    background: $blue;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 26px;
    @include respond-to('desktop') {
      margin-bottom: 2px;
    }
  }

  &__text-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.77px;
    color: #000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet_more') {
      font-size: 40px;
      line-height: 48px;
    }
    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }

    p {
      margin-bottom: 0;
      @include respond-to('desktop') {
        margin-left: -5px;
      }
    }
  }

  &__text-right {
    font-size: 18px;
    line-height: 24px;
    color: #000;
    @include respond-to('desktop') {
      font-size: 22px;
      line-height: 32px;
    }

    b, strong {
      font-family: $ubuntu-font;
      font-weight: bold;
    }

    &--extra-bold {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      color: #000;
      @include respond-to('tablet_more') {
        margin-top: 0;
      }
      @include respond-to('desktop') {
        font-size: 32px;
        line-height: 40px;
        margin-top: 11px;
      }
    }

    &--large {
      display: inline-block;
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 40px;
      line-height: 49px;
      letter-spacing: -0.65px;
      @include respond-to('tablet_more') {
        margin-top: 0;
        font-size: 40px;
        line-height: 58px;
      }
      @include respond-to('desktop') {
        font-size: 64px;
        line-height: 78px;
        letter-spacing: -1.03px;
        margin-bottom: 4px;
      }
      @include respond-to('mobile') {
        + br {
          display: none;
        }
      }
    }
  }

  .see-more {
    display: flex;
    align-items: center;
    height: 64px;
    width: 200px;

    .button-nav {
      text-decoration: none;
    }
  }

  .added-values {
    margin-top: 40px;
    margin-bottom: 40px;
    margin: 40px -11px;
    display: flex;
    flex-flow: row wrap;
    @include respond-to('tablet_more') {
      margin-top: 32px;
      margin-bottom: 33px;
    }
    @include respond-to('desktop') {
      margin-top: 85px;
      margin-bottom: 64px;
    }
    @include respond-to('hd') {
      margin-bottom: 78px;
    }
  }

  .bar-chart {
    height: 369px;
    margin: 56px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    @include respond-to('phone') {
      transform: rotate(90deg);
      height: 100vw;
      margin-top: 100px;
      margin-bottom: 100px;
      padding: 16px 0;
      justify-content: unset;
    }

    &:before {
      content: ' ';
      display: none;
      position: absolute;
      bottom: -38px;
      left: 0;
      background: url('../../ncimp/img/man-mobile.png') no-repeat center center;
      width: 52px;
      height: 132px;
      z-index: 1;
      @include respond-to('tablet_more') {
        display: inline-block;
      }
    }

    &__container {
      width: 304px;
      background: $blue;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      color: $white;
      font-family: $montserrat-font;
      font-weight: bold;
      height: 100%;
      transition: height 1s ease-in-out, opacity 1s, opacity 1s;
      opacity: 1;
      visibility: visible;
      @include respond-to('phone') {
        max-width: 135px;
      }

      &.hide {
        height: 0 !important;
        transition: height 1s ease-in-out;
      }

      &--full {
        background: $color-p2;
      }

      &--context {
        padding-bottom: 90px;
        transition: 1s;
        opacity: 0;
        margin-left: 13px;

        @include respond-to('phone') {
          transform: rotate(-90deg);
        }

        &.showing {
          transition-delay: 1s;
          opacity: 1;
        }

        &.hide {
          visibility: hidden;
          opacity: 0;
        }

        .pop-over {
          top: 4px;
          margin-right: 8px;
        }
      }

      &--current {
        font-size: 16px;
        line-height: 24px;

        @include respond-to('phone') {
          font-size: 14px;
          float: left;
          width: 100%;
        }
      }

      &--percent {
        font-size: 64px;
        line-height: 80px;
        letter-spacing: -1.03px;

        @include respond-to('phone') {
          font-size: 40px;
          line-height: 40px;
          float: left;
        }
      }

      &--value {
        font-size: 32px;
        line-height: 39px;

        @include respond-to('phone') {
          font-size: 18px;
          line-height: 1.0;
          float: right;
          margin-top: 17px;
        }
      }

      @include respond-to('phone') {
        margin-right: 10px;

        &__text {
          position: absolute;
          left: -45px;
          top: -40px;
          width: calc(100vw - 30px); // cały ekran - marginesy

          &--bottom {
            top: unset;
            bottom: -155px;
          }
        }
      }
    }
  }

  .grey-spacer {
    margin-top: 48px;
    @include respond-to('tablet_more') {
      margin-top: 42px;
    }
    @include respond-to('hd') {
      margin-bottom: 85px;
    }
  }
}
