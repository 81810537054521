.added-value {
  margin-top: 40px;
  padding: 0 11px;
  > * {
    max-width: 152px;
  }
  @include respond-to('desktop') {
    margin-top: 64px;
    > * {
      max-width: 244px;
    }
  }
  &__img {
    height: 88px;
    margin-bottom: 30px;
    @include respond-to('desktop') {
      height: 118px;
      margin-bottom: 36px;
    }
    @include respond-to('hd') {
      margin-bottom: 48px;
    }
    img {
      //width: 100%;
      max-height: 88px;
      @include respond-to('desktop') {
        max-height: 118px;
      }
    }
  }
  &__category {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    @include respond-to('desktop') {
      font-size: 16px;
    }
  }
  &__value {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    word-wrap: break-word;
    color: $blue;
    margin-top: 12px;
    @include respond-to('tablet_more') {
      margin-top: 8px;
    }
    @include respond-to('desktop') {
      font-size: 32px;
      line-height: 39px;
    }
  }
  &__description {
    font-size: 14px;
    line-height: 24px;
    color: #000;
    @include respond-to('desktop') {
      font-size: 16px;
    }
    @include respond-to('hd') {
      margin-top: 6px;
    }
  }
}
