@font-face {
  font-family: 'Montserrat';
  src: url('fonts/montserrat-bold.eot');
  src: url('fonts/montserrat-bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/montserrat-bold.woff2') format('woff2'),
       url('fonts/montserrat-bold.woff') format('woff'),
       url('fonts/montserrat-bold.ttf') format('truetype'),
       url('fonts/montserrat-bold.svg#montserratbold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('fonts/montserrat-regular.eot');
  src: url('fonts/montserrat-regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/montserrat-regular.woff2') format('woff2'),
       url('fonts/montserrat-regular.woff') format('woff'),
       url('fonts/montserrat-regular.ttf') format('truetype'),
       url('fonts/montserrat-regular.svg#montserratregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/ubuntu-bold.eot');
  src: url('fonts/ubuntu-bold.eot?#iefix') format('embedded-opentype'),
       url('fonts/ubuntu-bold.woff2') format('woff2'),
       url('fonts/ubuntu-bold.woff') format('woff'),
       url('fonts/ubuntu-bold.ttf') format('truetype'),
       url('fonts/ubuntu-bold.svg#ubuntubold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('fonts/ubuntu-regular.eot');
  src: url('fonts/ubuntu-regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/ubuntu-regular.woff2') format('woff2'),
       url('fonts/ubuntu-regular.woff') format('woff'),
       url('fonts/ubuntu-regular.ttf') format('truetype'),
       url('fonts/ubuntu-regular.svg#ubunturegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
