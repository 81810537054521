.list-cities {
  padding: 55px 0;
  @include respond-to('desktop') {
    padding-top: 127px;
  }

  @include respond-to('tablet') {
    display: none !important;
  }
  @include respond-to('hd') {
    padding-top: 107px;
  }
  .box-city {
    margin-top: 31px;
  }
  .see-more .button-nav {
    width: 200px;
    margin: 18px auto 0 auto;
    text-decoration: none;
  }
}
