.illustration {
  .mobile, .desktop {
    margin: 0 auto;
  }

  .mobile {
    width: 95%;
    @include respond-to(tablet_more) {
      display: none;
    }
  }

  .desktop {
    display: none;
    @include respond-to(tablet_more) {
      display: block;
    }
  }
}

#illustration-1 {
  width: 100%;
}

#illustration-1, #illustration-2, #illustration-3 {
  margin: 45px 0 5px;
  @include respond-to(tablet_more) {
    margin: 95px 0 5px;
  }
}

#illustration-3 {
  @include respond-to(tablet_more) {
    margin: 225px 0 40px;
  }
}
