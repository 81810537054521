.hp-intro {
  &--row--nowrap {
    @include respond-to('hd') {
      flex-wrap: nowrap;
    }
  }

  &--header {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin-top: 21px;
    @include respond-to('tablet_more') {
      margin-top: 34px;
    }
    @include respond-to('desktop') {
      margin-top: 42px;
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }
    @include respond-to('hd') {
      margin-top: 127px;
      font-size: 100px;
      letter-spacing: -1.61px;
      padding-left: 71px;
      text-align: left;
    }
  }

  .hp-form {
    @include respond-to('mobile') {
      text-align: center;
    }

    @include respond-to('tablet_more') {
      margin-top: 18px;
      margin-left: auto;
      margin-right: auto;
      max-width: 551px;
    }
    @include respond-to('hd') {
      padding-left: 43px;
      min-width: 634px;
    }

    &__form {
      @include respond-to('tablet_more') {
        margin: 41px 0 0 0;
        display: flex !important;
      }
      @include respond-to('desktop') {
        margin: 27px 0 0 0;
      }
    }

    &__title {
      font-family: $montserrat-font;
      font-size: 22px;
      font-weight: bold;
      line-height: 27px;
      justify-content: center;
      color: $blue;
      margin: 0;
      text-align: center;
      @include respond-to('desktop') {
        font-size: 32px;
        line-height: 39px;
        letter-spacing: 0px;
        margin-top: 7px;
      }
      @include respond-to('hd') {
        margin-top: 75px;
        justify-content: flex-start;
        text-align: left;
      }
    }

    &__subtitle {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #000;
      margin: 23px 0 0 0;
      display: inline-block !important;

      @include respond-to('mobile') {
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
      }

      @include respond-to('desktop') {
        font-size: 16px;
        line-height: 24px;
        margin-top: 14px;
        margin: 23px -19px 0 -18px;
      }
      @include respond-to('hd') {
        text-align: left;
        margin: 23px 25px 0 0;
      }
    }

    .hp-button {
      margin: 39px auto 31px auto;

      @include respond-to('tablet_more') {
        display: none !important;
      }
    }
  }

  &__col--img {
    height: 200px;
    width: 100%;
    padding: 0;
    max-width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    @include respond-to('phone') {
      background-size: cover;
    }

    @include respond-to('tablet') {
      height: 398px;
      margin-top: 91px;
    }

    @include respond-to('desktop') {
      height: 537px;
      margin-top: 38px;
    }

    @include respond-to('hd') {
      width: 1192px;
      height: 624px;
      flex-basis: auto;
      margin-top: 63px;
    }

    video {
      max-width: 100%;
      height: auto;
    }
  }

  &--row--padding013 {
    padding: 0 13px;
    @include respond-to('tablet_more') {
      padding: 0 24px;
    }
    @include respond-to('desktop') {
      padding: 0 40px;
    }

    .grey-spacer {
      margin-top: 48px;
      @include respond-to('tablet_more') {
        margin-top: 51px;
      }
      @include respond-to('desktop') {
        margin-top: 54px;
      }
      @include respond-to('hd') {
        height: 2px;
        max-width: 1140px;
        margin: 12px auto 0 auto;
      }
    }
  }

  &--tile {
    padding: 51px 20% 48px 20%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-flow: column;
    @include respond-to('tablet_more') {
      padding: 43px 51px 53px 51px;
      flex-flow: row;
    }
    @include respond-to('desktop') {
      padding: 34px 40px 46px 40px;
      justify-content: space-around;
    }
    @include respond-to('hd') {
      max-width: 1140px;
      margin: 0 auto;
      justify-content: space-between;
    }

    &--one {
      padding: 24px 0;
      font-family: $montserrat-font;
      font-size: 48px;
      font-weight: bold;
      line-height: 58px;
      letter-spacing: -0.77px;
      color: $blue;
      @include respond-to('desktop') {
        font-size: 64px;
        line-height: 78px;
        letter-spacing: -1.03px;
        padding: 0;
      }
      @include respond-to('hd') {
        padding: 0
      }

      p {
        font-size: 14px;
        line-height: 16px;
        color: #000;
        margin-bottom: 0;
        @include respond-to('desktop') {
          font-size: 16px;
          line-height: 27px;
        }
      }
    }
  }
}
