.privacy-policy {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 84px 16px 150px 16px;
  @include respond-to('phone') {
    max-width: 100%;
  }
  @include respond-to('tablet_more') {
    padding: 138px 42px 109px 41px;
  }
  @include respond-to('desktop') {
    padding: 103px 38px 157px 41px;
    max-width: 1140px;
  }

  .sticky-column-left {
    padding-bottom: 44px;
    @include respond-to('tablet_more') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
      padding-bottom: 84px;
    }
  }

  .column-right {
    @include respond-to('desktop') {
      position: relative;
      top: 84px;
      margin-bottom: 84px;
    }
  }

  &--section {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    width: 100%;
  }

  &__text-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.77px;
    color: #000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;

    h2 {
      hyphens: auto;
      word-break: break-word;
      font-size: inherit;
      line-height: inherit;
      letter-spacing: inherit;
    }

    @include respond-to('desktop') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
    }

    &--title {
      letter-spacing: -0.77px;
      margin-bottom: 0;
    }

    &--break-word {
      letter-spacing: -1.03px;
    }

    &--break-word2 {
      letter-spacing: -5.97px;
      @include respond-to('tablet_more') {
        letter-spacing: -0.77px;
      }
      @include respond-to('desktop') {
        letter-spacing: -0.77px;
      }

      .break-word {
        display: none;
        @include respond-to('desktop') {
          display: inline;
        }
      }
    }
  }

  .list-wrote {
    padding: 0 16px;
    @include respond-to('desktop') {
      margin-bottom: 33px;
    }
    @include respond-to('hd') {
      margin-bottom: 122px;
    }

    @include respond-to('phone') {

    }

    a {
      line-height: 32px;
      font-size: 22px;
      font-weight: normal;
      text-decoration: underline;
      @include respond-to('phone') {
        font-size: 18px;
      }
    }

    ol {
      font-weight: bold;

      li {
        line-height: 32px;
        font-size: 22px;
        font-weight: normal;

        @include respond-to('phone') {
          word-break: break-word;
        }
      }

      @include respond-to('phone') {
        font-size: 18px;
        margin-left: -20px;
      }
    }

    p {
      margin-bottom: 0;
      display: flex;
      margin-right: -15px;
      margin-left: -15px;
      line-height: 32px;
      @include respond-to('tablet_more') {
        font-size: 22px;
      }

      b {
        margin-right: 5px;
      }
    }
  }

  &--header {
    letter-spacing: -0.84px;
    margin-bottom: 30px;
    @include respond-to('tablet_more') {
      font-size: 64px;
      line-height: 78px;
      letter-spacing: -1.03px;
      margin-bottom: 286px;
    }
    @include respond-to('desktop') {
      font-size: 100px;
      line-height: 122px;
      letter-spacing: -1.61px;
      margin-bottom: 155px;
    }
  }

  .number-blue {
    display: inline-block;
    width: 40px;
    background: $blue;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
    @include respond-to('desktop') {
      margin-bottom: 2px;
    }
  }

  .grey-spacer {
    margin-top: 65px;
    margin-bottom: 36px;
    height: 6px;
    @include respond-to('tablet_more') {
      margin-top: 69px;
      margin-bottom: 69px;
      height: 7px;
    }
  }
}
