.hp-budget-realisation {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  padding: 75px 16px 0 16px;
  @include respond-to('phone') {
    max-width: 100%;
  }
  @include respond-to('tablet_more') {
    padding: 110px 26px 0 26px;
  }
  @include respond-to('desktop') {
    padding: 76px 10px 0 10px;
    max-width: 1140px;
  }
  .sticky-column-left {
    @include respond-to('desktop') {
      padding-right: 70px;
    }
  }
  &__tekst-sticky {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.77px;
    color:#000;
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include respond-to('tablet_more') {
      font-size: 48px;
      line-height: 56px;
    }
    @include respond-to('netbook') {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
      padding-right: 27px;
      padding-bottom: 20px;
    }
    p {
      margin-bottom: 0;
    }
    small {
      font-size: 22px;
      line-height: 32px;
      color: #000;
      font-weight: bold;
      display: inline-block;
      margin-bottom: 3px;
      @include respond-to('tablet_more') {
        margin-bottom: 10px;
      }
      @include respond-to('desktop') {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0;
        padding-right: 60px;
      }
    }
    span {
      color: $blue;
    }
  }
  &__box {
    width: 96px;
    height: 96px;
    background: $blue;
    color: #fff;
    text-align: center;
    padding: 16px 0;
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 56px;
    line-height: 72px;
    margin-bottom: 16px;
    float: left;
    @include respond-to('phone') {
      float: none;
    }
    @include respond-to('tablet_more') {
      margin-right: 32px;
      margin-bottom: 35px;
    }
  }
  &__text {
    display: flex;
    flex-wrap: wrap;
    @include respond-to('tablet_more') {
      flex-flow: column;
      margin-top: 8px;
    }
    @include respond-to('desktop') {
      margin-top: 0;
    }
  }
  &__header {
    @extend h3;
    color: #000;
  }
  &__caption {
    font-size: 14px;
    line-height: 24px;
    margin-top: 6px;
    margin-bottom: 0;
    color: #000;
    width: 100%;
    @include respond-to('desktop') {
      font-size: 16px;
      margin-top: 5px;
    }
  }
  &__col {
    margin-top: 40px;
    @include respond-to('tablet_more') {
      margin-top: 61px;
    }
  }
}
