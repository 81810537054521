.contact {
  margin: 140px 0;
  display: flex;
  @include respond-to('phone') {
    margin: 38px 1px 95px 1px;
    flex-direction: column;
  }
  @include respond-to('tablet') {
    flex-wrap: wrap;
    margin: 155px 0 184px 0;
  }
  @include respond-to('desktop') {
    margin: 141px 0 137px 0;
  }

  &__section {
    width: 50%;
    @include respond-to('tablet') {
      width: 100%;
    }
    @include respond-to('phone') {
      width: 100%;
    }
  }

  button {
    @include respond-to('phone') {
      margin: 0 auto;
    }
  }
}



.contact-title {
  font-size: 62px;
  padding-right: 45px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000000;
  @include respond-to('tablet') {
    font-size: 48px;
    margin-left: 9px;
  }
  @include respond-to('phone') {
    font-size: 48px;
  }
}

.contact-subtitle {
  display: block;
  margin-top: 85px;
  font-size: 62px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $blue;
  @include respond-to('tablet') {
    font-size: 48px;
  }
  @include respond-to('phone') {
    font-size: 40px;
    line-height: 58px;
    letter-spacing: -0.4px;
  }

  &:hover {
    text-decoration: none;
  }
}

.contact-form {
  margin-top: 60px;
  border-radius: 2px;
  box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
  background-color: #ffffff;
  padding: 40px 35px;
  position: relative;

  form.disabled {
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      z-index: 100;
    }
    > * {
      opacity: .6;
    }
  }
  @include respond-to('phone') {
    width: 100%;
    margin-top: 49px;
    padding: 40px 25px 40px 22px;
  }
  @include respond-to('tablet') {
    width: 78%;
    margin-top: 50px;
    padding: 40px 30px 42px 33px;
  }
  @include respond-to('desktop') {
    padding: 40px 30px 42px 33px;
  }

}

.contact-form-terms {
  margin-bottom: 45px;
  font-size: 16px;
  line-height: 1.5;
  color: #b1b7c0;
  @include respond-to(phone) {
    margin-right: -9px;
    margin-left: -2px;
    margin-bottom: 30px;
  }
  @include respond-to('tablet') {
    margin-bottom: 46px;
  }
  @include respond-to('desktop') {
    margin-bottom: 30px;
  }

  &.has-error .pretty.p-icon .state label:after {
    border-color: #d0021b;
  }
}

.contact-form-submit {
  position: relative;

  .contact-form-general-error {
    position: absolute;
    top: -35px;
    font-size: 16px;
    text-decoration: underline;
    line-height: 2;
    color: #d0021b;
    opacity: 0;
    transition: 0.5s;
    height: 0;
    @include respond-to('phone') {
      position: relative;
      top: unset;
      line-height: 1.4;
      margin-bottom: 10px;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }

  }

  &.has-error {
    .contact-form-general-error {
      opacity: 1;
      height: 100%;
    }
  }
}

.contact-form-require-error,
.contact-form-success-message {
  position: absolute;
  font-size: 16px;
  line-height: 2;
  color: $red;
  transition: 0.5s;
  opacity: 0;

  &.has-error {
    opacity: 1;

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
  }
}

.contact-form-success-message {
  position: relative;
  margin-top: 10px;
  display: none;

  .error-msg {
    line-height: 1.5;
  }

  &.has-error {
    display: block;
  }
}

.contact-form-group {
  position: relative;
  margin-bottom: 40px;
  @include respond-to('tablet_more') {
    margin-bottom: 50px;
  }
  @include respond-to('hd') {
    margin-bottom: 60px;
  }

  &__input {
    width: 100%;
    line-height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 18px;
    padding-right: 115px;
    color: #737579;
    border-bottom-color: rgba($grey, 0.3);
    @include respond-to('phone') {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__label {
    position: absolute;
    color: #b1b7c0;
    right: 0;
    top: 0;
    line-height: 32px;
    font-size: 16px;
    @include respond-to('phone') {
      font-size: 14px;
    }
    @include respond-to('tablet_more') {
      margin-bottom: 0;
      top: 4px;
    }
  }

  .error-msg {
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;

    ul {
      display: block;
      list-style: none;
      position: absolute;
      left: 0;
      top: 35px;
      font-size: 16px;
      line-height: 32px;
      padding-left: 0;
      @include respond-to('phone') {
        top: 30px;
        font-size: 14px;
      }
    }
  }

  &.has-error {
    .contact-form-group__input {
      border-bottom-color: #f0b2b9;
    }

    .error-msg {
      text-transform: lowercase;
      opacity: 1;
      visibility: visible;
      color: #d0021b;

    }
  }
}

.container-summary {
  margin-top: 64px;
  @include respond-to('tablet_more') {
    @include respond-to('desktop') {
      padding-right: 37px;
      padding-left: 37px;
    }
  }
  @include respond-to('hd') {
    margin-top: 88px;
  }

  .contact-title {
    font-size: 40px;
    @include respond-to('tablet_more') {
      margin-left: 0;
      font-size: 48px;
    }
    @include respond-to('desktop') {
      font-size: 62px;
    }
  }
}

.contact-summary {
  margin: 70px 0;
  display: flex;
  @include respond-to('tablet') {
    margin: 95px 0 65px 0;
  }
  @include respond-to('phone') {
    margin: 35px 0;
    flex-wrap: wrap;
  }
  @include respond-to('desktop') {
    margin: 59px 0 65px 0;
  }

  &__text {
    width: 40%;
    @include respond-to('tablet') {
      width: 50%;
    }
    @include respond-to('phone') {
      width: 100%;
      margin-bottom: 70px;
    }

    .contact-subtitle {
      margin-top: 0;
    }
  }

  &-info__group {
    &__label, &__input-result {
      font-size: 18px;
      line-height: 32px;
      @include respond-to('desktop') {
        font-size: 22px;
      }
    }
  }
}

.contact-summary-info {
  &__group {
    font-size: 22px;
    line-height: 1.45;
    color: #000000;
    display: flex;
    @include respond-to('tablet') {
      font-size: 18px;
      line-height: 1.78;
    }
    @include respond-to('phone') {
      width: 100%;
      padding-left: 11px;
    }

    &__input-result {
      margin-left: 3px;
      font-weight: bold;
    }
  }
}

.contact-summary-back-button {
  margin-top: 105px;
  @include respond-to('phone') {
    margin-top: 60px;
  }
  @include respond-to('tablet') {
    margin-top: 101px;
  }
  @include respond-to('desktop') {
    margin-top: 107px;
  }
}

.contact-summary-image {
  margin-top: 65px;
  width: 268px;
  height: 223px;
  @include respond-to('phone') {
    margin-bottom: 77px;
  }
}

.pretty {
  position: relative;
  display: inline-block;
  line-height: 1;

  * {
    box-sizing: border-box;
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:focus ~ .state {
      box-shadow: $btn-focus-box-shadow;
    }

    &:checked ~ .state {
      .icon {
        opacity: 1;
        animation: zoom .2s ease;
        color: $black;
        stroke: $white;

        label {
          &:after, &:before {
            background-color: #5cb85c !important;
          }

          &:before {
            transition: all .3s ease;
          }

          &:after {
            background-color: #5cb85c !important;
          }
        }
      }
    }
  }

  &.p-icon .state {
    .icon {
      position: absolute;
      font-size: 1em;
      width: 40px;
      height: 40px;
      left: 0;
      z-index: 1;
      text-align: center;
      line-height: normal;
      top: 0;
      border: 1px solid transparent;
      opacity: 0;
      transition: all .5s ease;
      margin-top: 6px;
      @include respond-to('tablet') {
        margin-top: 5px;
      }
      @include respond-to('desktop') {
        margin-top: 5px;
      }

      &:before {
        content: url('../../ncimp/img/check.png');
        margin: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        line-height: 1;
      }
    }

    label {
      position: initial;
      display: inline-block;
      font-weight: 400;
      margin: 0;
      min-width: calc(1em + 2px);
      font-size: 14px;
      line-height: 24px;
      color: $grey;
      @include respond-to(phone) {
        font-size: 16px;
      }
      @include respond-to('desktop') {
        font-size: 16px;
      }

      &:after, &:before {
        content: '';
        width: 40px;
        height: 40px;
        display: block;
        box-sizing: border-box;
        border-radius: 0;
        border: 5px solid $blue;
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        background-color: transparent;
        transition: all .5s ease;
        margin-top: 6px;
      }

      &:before {
        border-color: #bdc3c7;
      }

      .virtual_check {
        width: 50px;
        height: 40px;
        display: inline-block;
        float: left;
        margin-top: 6px;
        @include respond-to(phone) {
          width: 44px;
        }
      }
    }
  }
}

