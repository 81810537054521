.cookies-box {
  position: fixed;
  bottom: 0;
  background-color: rgba(5, 100, 250, 0.9);
  color: #fff;
  padding: 27px 24px 18px 15px;
  font-family: $ubuntu-font;
  font-size: 14px;
  line-height: 24px;
  z-index: 1040;
  @include respond-to('desktop') {
    padding: 30px 103px 24px 39px;
  }
  &__title {
    font-family: $ubuntu-font;
    font-weight: bold;
    margin-bottom: 11px;
  }
  &__close {
    position: absolute;
    top: 8px;
    right: 12px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: 0.3s all;
    @include respond-to('tablet_more') {
      top: 22px;
      right: 33px;
    }
    &:before, &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      top: 50%;
      transition: 0.3s all;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
    &:hover {
      &:before {
        transform: rotate(135deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }

  a {
    font-size: 14px;
    color: #fff;
    text-decoration: underline;

    &:hover { color: rgba(#fff, 0.8); }
  }
}
