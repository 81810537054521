@import "../../ncimp/variables";

.advanced {
  &__switch {
    font-size: 16px;
    line-height: 24px;
    color: $grey;
    margin-bottom: 27px;
    display: inline-flex;

    &__text {
      text-decoration: underline;
    }

    &__arrow {
      width: 30px;
      height: 20px;
      position: relative;
      white-space: nowrap;
      display: block;
      transition: 0.5s;
      transform: translateY(-2px);
      margin-left: 7px;

      &:before {
        content: ' ';
        width: 14px;
        position: absolute;
        left: 4px;
        top: 14px;
        height: 3px;
        background: $grey;
        transform: rotate(45deg);
      }

      &:after {
        content: ' ';
        width: 14px;
        position: absolute;
        right: 4px;
        top: 14px;
        height: 3px;
        background: $grey;
        transform: rotate(-45deg);
      }
    }

    &:hover .advanced__switch {
      &__text {
        color: $black;
      }

      &__arrow {
        &:before,
        &:after {
          background: $black;
        }
      }
    }
  }

  &__settings {
    display: none;
    margin-bottom: 33px;

    &__checkbox {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .pretty.p-icon {
        position: relative;

        .state {
          display: flex;
          width: 100%;
          align-items: center;

          @mixin make-checkbox() {
            margin-top: 0;
            width: 24px;
            height: 24px;
            transform: translateY(2px);
          }

          .icon {
            @include make-checkbox();
            transform: translateY(2px) scale(0.9);
          }

          label {
            font-size: 18px;
            line-height: 28px;
            color: $black;
            display: flex;
            align-items: center;

            .virtual_check,
            &:before,
            &:after {
              @include make-checkbox();
              margin-right: 12px;
              border-width: 3px;
            }
          }
        }

        .pop-over {
          position: absolute;
          top: -3px;
          right: -30px;
        }
      }
    }
  }

  &.is-active .advanced {
    &__switch__arrow {
      transform: rotate(-180deg) translateY(-7px);
    }

    &__settings {
      display: block;
    }
  }
}
