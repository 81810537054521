.sticky-column-left {
  width: 100%;
  top: 83px;
  @include respond-to('hd') {
    top: 88px;
  }
  @include respond-to('desktop') {
    width: calc(43% - 2px);
    position: sticky;
    position: -webkit-sticky;
    display: inline-block;
    vertical-align: top;
  }
}

.column-right {
  width: 100%;
  @include respond-to('desktop') {
    width: calc(57% - 3px);
    display: inline-block;
    vertical-align: top;
  }
}

.container.second-module {
  @include respond-to('mobile') {
    padding-top: 0;
    .box-budget__left {
      display: none;
    }
  }
  @include respond-to('phone') {
    margin-top: 10px;
  }
}

.box-budget {
  width: 100%;

  &__left {
    width: 100%;
    top: 64px;
    vertical-align: top;
    @include respond-to('desktop') {
      width: calc(43% - 2px);
      position: sticky;
      position: -webkit-sticky;
      display: inline-block;
      top: 80px;
    }
    @include respond-to('hd') {
      top: 110px;
    }
    padding-bottom: 50px;
    @include respond-to('tablet') {
      padding-right: 50px;
    }
    @include respond-to('desktop') {
      padding-right: 70px;
    }
    @include respond-to('hd') {
      padding-bottom: 130px;
      max-width: calc(1140px * 0.43);
      margin-left: calc(50% - (1140px / 2));
    }

    &__text {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.77px;
      color: #000;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      @include respond-to('tablet') {
        font-size: 40px;
        line-height: 48px;
      }
      @include respond-to('desktop') {
        font-size: 64px;
        line-height: 80px;
        letter-spacing: -1.03px;
      }

      h2 {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        margin-bottom: 0;
        @include respond-to('desktop') {
          margin-left: -2px;
        }
      }
    }

    &--number-blue {
      display: inline-block;
      width: 40px;
      background: $blue;
      line-height: 40px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      margin-bottom: 26px;
      @include respond-to('desktop') {
        margin-bottom: 2px;
      }
    }
  }

  &__right {
    width: 100%;

    &--investments-margin {
      @include respond-to('desktop') {
        padding-left: 95px;
      }

      .block-text {
        .bold-blue {
          margin-top: 19px;
        }
      }
    }

    @include respond-to('desktop') {
      width: calc(57% - 3px);
      display: inline-block;
    }
    @include respond-to('desktop') {
      position: relative;
      top: 79px;
      margin-bottom: 84px;
    }
    @include respond-to('hd') {
      width: calc(1140px * 0.57 + 50% - (1140px / 2));
    }

    .block-text {
      font-family: $ubuntu-font;
      font-size: 22px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 5px;
      float: left;
      width: 100%;

      &:before,
      &:after {
        clear: both;
        content: ' ';
        display: table;
      }

      .investment-text-break {
        br {
          display: none;
          @include respond-to('desktop') {
            display: block;
          }
        }
      }

      .investment-info {
        display: none;

        @include respond-to('mobile') {
          display: block;
          font-family: $ubuntu-font;
          font-size: 11px;
          margin-top: 32px;
          margin-bottom: 29px;
        }

        @include respond-to('phone') {
          margin-top: 9px;
          margin-bottom: 0;
        }
      }

      .bold-blue {
        font-family: $montserrat-font;
        font-size: 56px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $blue;
        margin-right: 10px;

        @include respond-to('phone') {
          font-size: 33px;
          line-height: 1.45;
          letter-spacing: -0.5px;
        }

        @include respond-to('tablet') {
          font-size: 40px;
        }
      }

      > div.inline {
        display: inline-block;
        vertical-align: baseline;
      }

      &--position-fix {
        margin-top: -13px;
      }

      @include respond-to('tablet') {
        font-size: 18px;
      }

      @include respond-to('phone') {
        font-size: 18px;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 24px;
      color: #000;

      @include respond-to('tablet') {
        line-height: 32px;
      }
      @include respond-to('desktop') {
        font-size: 22px;
        line-height: 32px;
      }
      @include respond-to('hd') {
        width: calc(1140px * 0.57);
        display: inline-block;
        padding-right: 10px;
      }

      b, strong {
        font-family: $ubuntu-font;
        font-weight: bold;
      }
    }
  }
}

#citizens,
#fund-solecki {
  .slide-box {
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 100%;
    display: flex !important;
    overflow: hidden;
    @include respond-to('phone') {
      height: 158px;
    }

    img {
      max-height: unset;
      max-width: unset;
      margin: unset;
      width: 100%;
      height: auto;
    }
  }

  .slider {
    .counter {
      display: none;
      @include respond-to('desktop') {
        position: absolute;
        display: block;
        right: 120px;
        padding: 0 16px 0 0;
        bottom: -74px;
        margin: 0;
      }
    }

    .slick-arrow {
      bottom: -85px;
      @include respond-to('tablet') {
        top: unset;
        bottom: 0;

        &.slick-prev {
          right: calc(40px + 18px); // szerokosc + margines
        }
      }
    }
  }
}

.slider {
  &-container {
    display: block;
    flex-flow: column;
    padding: 44px 0 30px 0;
    @include respond-to('tablet') {
      margin-right: -15px;
      margin-left: -15px;
      padding-bottom: 68px;
    }
    @include respond-to('desktop') {
      padding: 80px 0 44px 0;
      position: relative;
    }
  }

  &-img {
    width: 100%;
    margin-bottom: 32px;
    @include respond-to('tablet') {
      margin-bottom: 15px;
      position: static;
    }
    @include respond-to('desktop') {
      margin-bottom: 20px;
    }
    @include respond-to('hd') {
      margin-bottom: 27px;
    }
  }

  &-content {
    width: 100%;
    @include respond-to('hd') {
      width: calc(1140px * 0.57) !important;
    }

    .slick-slide {
      @include respond-to('tablet') {
        margin-right: 15px;
        margin-left: 15px;
      }
    }

    .slider-context__costs-text, .slider-context__costs-value {
      color: #000;
      margin-bottom: 0;
    }

    .slider-context__costs-text {
      font-size: 11px;
      line-height: 18px;
      @include respond-to('desktop') {
        font-size: 13px;
        line-height: 18px;
      }
    }

    .slider-context__costs-value {
      font-family: $montserrat-font;
      font-weight: bold;
      font-size: 22px;
      line-height: 32px;
      margin-top: 10px;
      @include respond-to('desktop') {
        margin-top: 4px;
        margin-bottom: 13px;
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  .slide {
    &-caption {
      margin-top: 21px;
      font-size: 14px;
      line-height: 18px;

      p.title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 4px;
      }
    }

    &-context {
      display: block;
      margin: 0;

      [class*="col"] {
        padding: 0;
      }
    }

    &-box {
      display: block;
      justify-content: center;
      align-items: center;
    }

    &__box {
      display: inline-block;
      vertical-align: top;

      &--vote {
        margin-left: 70px;
        @include respond-to('phone') {
          margin-left: 34px;
        }
      }

      &--counter {
        margin-left: 70px;
        @include respond-to('phone') {
          margin-left: 0;
          display: none;
        }
      }

      &--counter {
        margin-top: 35px;
        @include respond-to('tablet') {
          position: absolute;
          bottom: 70px;
        }
        @include respond-to('phone') {
          margin-left: 0;
          bottom: 0;
        }
      }
    }
  }

  &.slider-img .slick-slide {
    @include respond-to('tablet') {
      max-height: 24.5vw;
      margin-right: 15px;
      margin-left: 15px;
    }
    @include respond-to('hd') {
      max-height: 350px;
    }

    .slide-img {
      max-height: 350px;
      height: auto;
      width: auto;
      margin: auto;
      max-width: 100%;

      @include respond-to('phone') {
        max-height: 208px;
        height: auto;
        width: auto;
        margin: auto;
        max-width: 100%;
      }
    }
  }

  .progress {
    width: 100%;
    height: 2px;
    background: rgba($grey, 0.3);
    @include respond-to('hd') {
      width: calc(1140px * 0.57);
    }

    &-bar {
      width: 0%;
      background: $blue;
      transition: width 5s;
    }
  }

  .slick {
    &-arrow {
      width: 40px;
      height: 40px;
      padding: 0;
      border: 0 solid;
      border-radius: 0 !important;
      background-color: #000;
      color: #fff;
      line-height: 40px;
      text-align: center;
      font-size: 30px;
      margin-left: 2px;
      transition: 0.3s;
      font-weight: 400;
      position: absolute;
      bottom: 0;
      right: 15px;
      z-index: 9;

      &.slick-prev, &.slick-next {
        &:after {
          content: ' ';
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -8px;
          // bazowo powinno być -6px ale wizualnie trzeba lekko przesunąć środek
          margin-left: -7px;
          width: 12px;
          height: 17px;
        }
      }

      &.slick-prev {
        &:after {
          background: url('../../ncimp/img/slick-arrow-prev.png') center no-repeat;
          background-size: contain;
        }
      }

      &.slick-next {
        &:after {
          background: url('../../ncimp/img/slick-arrow-prev.png') center no-repeat;
          background-size: contain;
          transform: rotate(180deg);
          margin-left: -5px;
        }
      }

      &.slick-pause {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 99px;
        left: auto !important;
        background-color: #fff;
        @include respond-to('desktop') {
          right: 84px;
        }

        .play {
          display: none;
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7px 0 7px 12px;
          border-color: transparent transparent transparent #000000;
        }

        .pause {
          display: flex;
          justify-content: space-between;
          width: 12px;

          &::before, &::after {
            content: '';
            display: block;
            width: 4px;
            height: 14px;
            background-color: #000;
          }
        }

        &.paused {
          .pause {
            display: none;
          }

          .play {
            display: block;
          }
        }

        &:hover {
          background: none !important;

          .pause {
            &:before, &:after {
              background-color: $blue;
            }
          }

          .play {
            border-color: transparent transparent transparent $blue;
          }
        }
      }

      @include respond-to('phone') {
        display: none;
      }
      @include respond-to('desktop') {
        bottom: -72px;
        right: 0;
      }
      @include respond-to('hd') {
        bottom: -82px;
        left: calc(1140px * 0.57 - 42px) !important;
      }
      @include respond-to('tablet') {
        top: 440px;
        bottom: unset;
      }
    }

    &-prev {
      margin-left: 0;
      right: 57px;
      @include respond-to('desktop') {
        right: 42px;
      }
      @include respond-to('hd') {
        left: calc(1140px * 0.57 - 82px) !important;
      }
    }
  }
}

span.blue {
  color: $blue;
}

.counter {
  display: none;
  @include respond-to('tablet_more') {
    font-size: 14px;
    line-height: 16px;
    color: #000;
    font-family: $montserrat-font;
    font-weight: bold;
    padding: 0 16px 4px 0;
    right: 97px;
    bottom: 0;
  }
}

.pagination {
  margin-bottom: 0;
}

#nav-budget {
  position: static;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;
  background: white;
  height: 68px;
  align-items: flex-end;
  padding: 0;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;

  @include respond-to('hd') {
    height: 92px;
  }

  .container {
    height: 100%;
    align-items: flex-end;
    display: flex;
    padding-left: 0;
    padding-bottom: 4px;
  }

  &:before {
    content: ' ';
    display: inline-block;
    background: $white;
    width: 100vw;
    height: 100%;
    position: absolute;
    left: calc((100vw - 1140px) / -2);
  }

  .nav-pills {
    position: absolute;
    margin-top: 3px;
    width: 100%;

    &:before, &:after {
      content: ' ';
      display: inline-block;
      width: 100%;
      position: absolute;
    }

    &:before {
      border-bottom: 4px solid $white;
      bottom: 0;
    }

    &:after {
      border-bottom: 1px solid $grey;
      bottom: 3px;
    }
  }

  .nav-item {
    position: relative;

    &:after {
      content: "";
      display: inline-block;
      border-bottom: 1px solid $grey;
      width: 100%;
      position: absolute;
      margin-top: -4px;
    }
  }

  .nav-link {
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 0 11px 4px 11px;
    border-bottom: 4px solid $white;
    border-radius: 0;
    background: transparent;
    transition: 0.3s;
    position: relative;

    &.active, &:hover {
      color: #000;
      border-bottom: 4px solid #000;
      z-index: 1;
    }
  }
}

.hr-only-mobile {
  margin-top: 101px !important;
  display: none;
  @include respond-to('phone') {
    display: block;
  }
}

.is-demo {
  .bold-blue {
    @include respond-to('desktop') {
      margin-bottom: 150px;
    }
  }
}
