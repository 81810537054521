.stats-container {
  @include respond-to(tablet_more) {
    display: flex;
  }

  .stats {
    margin-bottom: 95px;
    @include respond-to(tablet_more) {
      min-width: 400px;
    }

    .stat {
      font-family: $montserrat-font;
      font-weight: bold;
      margin-bottom: 40px;

      .number {
        color: $blue;
        font-size: 56px;
        @include respond-to(tablet_more) {
          font-size: 96px;
          line-height: 0.9;
        }
      }

      .description {
        font-size: 14px;
      }
    }
  }
}
