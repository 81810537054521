.intro {
  padding: 44px 0 0;
  position: relative;
  @include respond-to(tablet_more) {
    padding: 138px 0 45px;
    min-height: 640px;
  }

  h1 {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 24px;
    @include respond-to(tablet_more) {
      font-size: 64px;
      line-height: 80px;
      letter-spacing: -1.03px;
      margin-bottom: 61px;
    }
  }

  &.non-client-intro {
    @include respond-to(tablet_more) {
      padding-bottom: 0;

      h1 {
        margin-bottom: 180px !important;
      }
    }
  }

  .location-logo {
    margin-bottom: 64px;
    max-width: 100%;
    height: auto;
    @include respond-to(tablet_more) {
      margin-bottom: 91px;
    }
  }

  .button-budget {
    white-space: nowrap;
    padding: 23px 0;
    margin: 0 auto 44px;
    @include respond-to(tablet_more) {
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .location-map {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    height: auto;
    @include respond-to(tablet_more) {
      position: absolute;
      bottom: 96px;
      right: -40px;
      z-index: -1;
    }
  }
}
