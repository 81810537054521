.cta {
  margin-bottom: 80px;
  @include respond-to(tablet_more) {
    margin-bottom: 120px;
  }
  @include respond-to(desktop) {
    padding-left: 43%;
  }

  p {
    font-size: 22px;
    line-height: 32px;
    max-width: 570px;
    margin: 0 0 8px;
  }

  .bigger {
    font-family: $montserrat-font;
    font-size: 32px;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 50px;
    @include respond-to(tablet_more) {
      margin-bottom: 70px;
    }
  }
}
