.items-pagination {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;

  a, span {
    font-family: $montserrat-font;
    font-size: 14px;
    font-weight: bold;
    color: $black;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .prev, .next {
    a, span {
      background-color: $black;
      color: $white;
    }

    span {
      opacity: .5;
    }

    i {
      border: solid white;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 5px;
      position: relative;
    }
  }

  .prev {
    i {
      transform: rotate(135deg);
      left: 2px;
    }
  }

  .next {
    i {
      transform: rotate(-45deg);
      left: -3px;
    }
  }

  .pages {
    text-align: center;

    span {
      background-color: $blue;
      color: $white;
    }
  }
}
