.last-budget {
  width: 100%;
  @include respond-to(tablet_more) {
    display: flex;
  }

  .title-container {
    display: flex;
    align-items: center;
    padding: 0 21px;
    margin-bottom: 60px;
    @include respond-to(tablet_more) {
      min-width: 345px;
      max-width: 345px;
      margin-left: auto;
      padding-left: 0;
    }
    @include respond-to(netbook) {
      margin-bottom: 33px;
      min-width: 369px;
      max-width: 369px;
    }
    @include respond-to(desktop) {
      min-width: 555px;
      max-width: 555px;
    }

    h2 {
      font-size: 22px;
      line-height: 32px;
      color: $white;
      white-space: nowrap;
      @include respond-to(netbook) {
        font-size: 48px;
        line-height: 80px;
      }
    }

    .button-arrow {
      display: inline-flex;
      width: 28px;
      height: 28px;
      padding: 0;
      margin-left: 11px;
      position: relative;
      top: -1px;
      @include respond-to(desktop) {
        width: 60px;
        height: 60px;
        margin-left: 22px;
        top: -2px;

        svg {
          width: 18px;
          height: 30px;
        }
      }
    }
  }

  .graph-stacked {
    padding: 0 21px;

    h3 {
      font-size: 32px;
      line-height: 40px;
      color: $white;
      margin-bottom: 42px;

      span {
        display: block;
        color: $violet-1;
        white-space: nowrap;
      }
    }

    .graph {
      display: flex;
      height: 306px;
      @include respond-to(tablet_more) {
        height: 456px;
      }

      .entries {
        flex: 1;
        transform: translateY(19px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .entry {
          display: flex;
          justify-content: space-between;
          min-height: 3px;

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .label {
            font-size: 10px;
            line-height: 12px;
            font-weight: bold;
            color: $white;
            text-align: right;
            flex: 1;
            margin: 0 7px;
            @include respond-to(tablet_more) {
              font-size: 13px;
              line-height: 16px;
              margin: 0 12px;
            }

            span {
              margin-right: 7px;
              @include respond-to(tablet_more) {
                margin-right: 12px;
              }
            }
          }

          .box {
            width: 29px;
            background-color: $violet-1;
            max-width: 160px;
            @include respond-to(desktop) {
              width: calc(40% - 32px);
            }
          }

          &.with-hidden-label {
            .label {
              opacity: 0;
              transform: translateY(-4px);
              transition: opacity 200ms;
            }

            &.label-appear {
              .label {
                opacity: 1;
              }
            }
          }
        }
      }

      .skewed-bar {
        background-color: #4D65EB;
        min-width: 72px;
        max-width: 72px;
        transform: skewY(-15deg) translateY(9.5px);
      }

      .bar {
        background-color: $violet-1;
        min-width: 24px;
        max-width: 24px;
      }
    }
  }

  .incomes, .expenses {
    padding: 34px 0 64px;
    @include respond-to(tablet_more) {
      min-width: 50%;
      max-width: 50%;
      padding-top: 43px;
    }
  }

  .incomes {
    background-color: $dark-grey;

    .graph-stacked h3 {
      text-align: right;
      @include respond-to(tablet_more) {
        padding-right: 19px;
      }
    }
  }

  .expenses {
    background-color: #061E39;
    @include respond-to(tablet_more) {
      padding-top: 135px;
    }
    @include respond-to(netbook) {
      padding-top: 156px;
    }

    .graph-stacked {
      h3 {
        @include respond-to(tablet_more) {
          padding-left: 19px;
        }
      }

      .graph {
        flex-direction: row-reverse;

        .entries {
          .entry {
            flex-direction: row-reverse;

            .label {
              text-align: left;

              span {
                margin-right: 0;
                margin-left: 7px;
                @include respond-to(tablet_more) {
                  margin-right: 0;
                  margin-left: 12px;
                }
              }
            }

            .box {
              background-color: $blue;
            }
          }
        }

        .skewed-bar {
          transform: skewY(15deg) translateY(9.5px);
          background-color: #023d9b;
        }

        .bar {
          background-color: $blue;
        }
      }
    }
  }
}
