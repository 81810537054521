// Kolory projektu
$blue: #0564fa;
$black: #00122d;
$dark-grey: #33394b;
$grey: #b1b7c0;
$violet-1: #6f83ff;
$violet-2: #a7b4ff;
$violet-3: #c7d0ff;
$violet-4: #d7ddff;
$white: #fff;
$green: #5abe56;
$red: #d0021b;

// Domyślne kolory budżetu
$color-brand1: #0564fa;
$color-brand2: #5e77ff;
$color-contrast: #fff;
$color-p2: #071f4a;
$color-d3: #aab4f9;
$color-d4: #c9d0fb;


// Punkty przełamania
$break-small: 320px;
$break-tablet: 767px;
$break-netbook: 992px;
$break-large: 1200px;
$break-hd: 1920px;

// Czcionki
$ubuntu-font: 'Ubuntu', sans-serif;
$montserrat-font: 'Montserrat', sans-serif;
