html {
  -webkit-overflow-scrolling: touch;
}

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: $ubuntu-font;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: $black;

  &.msie {
    .nav-pills {
      width: auto !important;
      margin-top: -24px !important;
    }

    .city-intro--left {
      top: 87px;
    }

    .module-list {
      &__right {
        .one-news {
          width: 50% !important;
        }
      }
    }

    .taxes-bottom-text {
      a {
        display: block;
      }
    }

    .contact-form-group {
      &__input {
        height: 34px;
      }
    }
    .taxes-modal-slider-bottom-group {
      width: 33%;
      display: inline-block;
      margin-bottom: 40px;
    }
  }
}

#container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

button, input[type="button"], input[type="submit"], a[role="button"], .role-button {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  font-family: $montserrat-font;
  height: 64px;
  width: 200px;
  font-weight: bold;
  letter-spacing: normal;
  text-align: center;
  color: $white;
  background: $violet-1;
  transition: 0.3s;
  text-transform: uppercase;
  padding: 0 35px;
  justify-content: center;
  border: 0 solid;
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 0 rgba($white, 0) !important;
  }

  &:hover {
    color: #fff;
    background: $blue !important;
    text-decoration: none;
  }
}

a {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: $grey;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: $blue;
    text-decoration: none;
  }
}

.see-more a {
  text-decoration: underline;
}

.grey-spacer {
  width: 100%;
  height: 8px;
  background: rgba($grey, 0.3);
  margin-top: 33px;
}

.hidden {
  display: none !important;
}

.visibility {
  visibility: hidden !important;
  z-index: -1;
}

.whiteBG {
  background: $white !important;
}

/*paginacja*/

a.page-link.slide {
  &-prev, &-next {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 0 solid;
    border-radius: 0 !important;
    background-color: #000;
    color: #fff;
    line-height: 37px;
    text-align: center;
    font-size: 30px;
    margin-left: 2px;
    transition: 0.3s;

    &:focus {
      box-shadow: 0 0 0 0 rgba($white, 0);
    }

    &:hover {
      background-color: $blue;
    }
  }
}

.popover-body {
  p:last-of-type {
    margin-bottom: 0 !important;
  }
}

.hp-form {
  margin-top: 40px;
  @include respond-to('tablet_more') {
    margin-top: 61px;
  }

  &__header {
    @extend h3;
    font-size: 32px;
    line-height: 39px;
    color: $blue;
    @include respond-to('tablet_more') {
      margin: -9px 0 33px 96px;
    }
    @include respond-to('desktop') {
      margin: -9px 0 33px 0;
    }
  }

  &__form {
    @include respond-to('tablet_more') {
      margin-bottom: 77px;
      margin-left: 96px;
    }
    @include respond-to('desktop') {
      margin-bottom: 126px;
      margin-left: 0;
    }
  }

  &__email {
    width: 344px;
    line-height: 62px;
    border: 1px solid $grey;
    font-size: 16px;
    padding: 0 19px;
    font-family: $montserrat-font;
    font-weight: bold;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0;
    -webkit-border-radius: 0px;

    &::placeholder {
      color: $grey;
    }
  }
}

.hp-button {
  margin-top: 34px;
  margin-bottom: 42px;
  font-family: $montserrat-font;
  font-weight: bold;
}

/*Perfect Scrollbar*/

.ps {
  position: relative;
  width: 100%;
  height: 100%;

  &__thumb-x, &__thumb-y {
    background: rgba($grey, 0.61) !important;
    border-radius: 0;
  }

  &__thumb-x {
    height: 4px;
  }

  &__thumb-y {
    border-radius: 0;
    width: 4px;
    right: 0;
  }

  &__rail-y, &__rail-x {
    background: rgba($grey, 0.2) !important;
    opacity: 1 !important;
    z-index: 1021;
  }

  &__rail-y {
    width: 4px !important;
  }

  &__rail-x {
    height: 4px !important;
  }
}

.clear-float {
  float: none !important;
}

/*tooltip*/
.pop-over {
  width: 16px;
  height: 17px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  float: left;
  margin-right: 5px;

  svg rect, svg circle {
    transition: fill .3s ease;
  }

  &:hover {
    .info-ico--g {
      fill: $blue;
    }
  }

  .info-ico--g {
    fill: #000;
    transition: 0.3s;
  }

  &--blue {
    &:hover {
      .info-ico--g {
        fill: $black;
      }
    }

    .info-ico--g {
      fill: $white;
    }
  }
}

.popover {
  box-shadow: 0 0 30px 0 rgba(51, 57, 75, 0.2);
  background: #fff;
  border-radius: 0;
  border: 0 solid;
  font-family: $ubuntu-font;
  font-size: 13px;
  line-height: 18px;
  z-index: 999;
  max-width: 408px;
  display: block;

  &.bs-popover-top {
    top: -13px !important;
  }

  &.bs-popover-bottom {
    top: 13px !important;
  }

  @include respond-to('mobile') {
    &.bs-popover-top {
      top: -5px !important;
    }
    &.bs-popover-bottom {
      top: 65px !important;
    }
  }

  &-body {
    padding: 28px 40px;
  }
}

[role=button], a, area, button, input:not([type=range]), label, select, summary, textarea {
  touch-action: manipulation;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

.container {
  @include respond-to('tablet') {
    width: 768px;
  }
}

a.dropdown-item {
  cursor: pointer;
}
