.numbered {
  font-size: 11px;
  padding: 95px 0 5px;
  @include respond-to(tablet_more) {
    font-size: 13px;
  }

  .section-number {
    background-color: $blue;
    color: $white;
    width: 40px;
    height: 40px;
    font-family: $montserrat-font;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 28px;
  }

  @include respond-to(desktop) {
    .columns {
      display: flex;

      .column {
        max-width: 560px;

        &:first-child {
          min-width: 475px;
        }
      }
    }
  }

  .graph {
    max-width: 300px;
    margin: 0 auto;
    display: none;
    @include respond-to(desktop) {
      display: block;
    }
  }

  #graph-2 {
    margin: 0;
    display: block;
  }

  h3 {
    margin-bottom: 45px;
  }

  .legend {
    font-family: $montserrat-font;
    font-weight: bold;
    margin-bottom: 45px;
    @include respond-to(tablet_more) {
      font-size: 11px;
    }

    .entry {
      display: flex;
      margin-bottom: 14px;

      .box {
        min-width: 28px;
        max-width: 28px;
        height: 28px;
        margin-right: 12px;

        &.type-1 {
          background-color: $blue;
        }

        &.type-2 {
          background-color: $violet-1;
        }

        &.type-3 {
          background-color: $violet-2;
        }

        &.type-4 {
          background-color: $violet-4;
        }

        &.type-5 {
          background-color: $grey;
        }

        &.type-correct {
          background-color: $green;

          ~ .value .number {
            color: $green;
          }
        }
      }

      .number {
        color: $grey;
        font-size: 14px;
      }
    }
  }
}
