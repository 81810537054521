// vendors
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~slick-carousel/slick/slick.css';
@import '~bootstrap/scss/bootstrap';

@import 'variables';
@import 'functions';
@import 'fonts';
@import 'nav';
@import 'footer';
@import 'cookies';
@import '../components/typo/typo';
@import '../components/special/404';
@import '../components/special/privacy_policy';
@import '../components/special/pit-info';
@import '../components/spinner/spinner';
@import '../components/pagination/pagination';
@import '../components/search-city/search-city';
@import '../vendors/cssmapsplugin/cssmap/_cssmap-poland.css';
@import '../components/hp/hp-intro';
@import '../components/hp/hp-map';
@import '../components/hp/hp-list-city';
@import '../components/hp/hp-what-say';
@import '../components/hp/hp-presentation';
@import '../components/hp/hp-budget-realisation';
@import '../components/hp/hp-wrote';
@import '../components/list-city/list-city';
@import '../components/tags/tags';
@import '../components/budget/global';
@import '../components/budget/city-intro';
@import '../components/budget/demo-intro';
@import '../components/budget/city-citizens';
@import '../components/budget/city-fund-solecki';
@import '../components/budget/city-forecast-pit';
@import '../components/budget/added-value';
@import '../components/budget/city-summary';
@import '../components/budget/city-incomes';
@import '../components/budget/city-expanses';
@import '../components/budget/city-taxes';
@import '../components/budget/city-investments';
@import '../components/budget/charts';
@import '../components/budget/not-precise-modal';
@import '../components/budget/budgets-list';
@import '../components/about-project/about-project';
@import '../components/news/news';
@import '../components/contact/contact';
@import '../components/report/report';
@import '../components/locations/location';
@import '../components/sitemap/sitemap';
@import 'general';
