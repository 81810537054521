footer {
  background: $dark-grey;
  width: 100%;
  .container {
    padding: 36px 21px 0 21px;
    align-self: flex-end;
    color: $white;
    transition: 0.5s;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    max-width: inherit;
    @include respond-to('tablet_more') {
      padding: 36px 24px 0 24px;
      align-items: flex-start;
    }
    @include respond-to('desktop') {
      width: 1120px;
      padding: 60px 0 0 0;
    }
    > * {
      width: 100%;
    }
    .navbar-brand {
      font-family: $montserrat-font;
      text-transform: uppercase;
      padding: 0;
      font-size: 16px;
      margin-top: -11px;
      line-height: 16px;
      @include respond-to('tablet_more') {
        width: 50%;
        margin-right: 0;
        order: 1;
      }
      @include respond-to('desktop') {
        margin-top: 0;
        width: 43%;
      }
      img {
        width: 22px;
        vertical-align: bottom;
        margin-right: 13px;
        @include respond-to('tablet_more') {
          margin-right: 18px;
        }
        @include respond-to('desktop') {
          width: 25px;
          vertical-align: top;
        }
        @include respond-to('hd') {
          margin-right: 20px;
        }
      }
      > span {
        font-weight: bold;
        display: inline-block;
        white-space: pre-line;
        vertical-align: super;
        color: $white;
        @include respond-to('desktop') {
          margin-top: -5px;
        }
        span {
          opacity: 0.5;
          margin-bottom: 0;
        }
      }
    }
    .footer {
      width: 100%;
      &-right {
        @include respond-to('tablet_more') {
          width: 50%;
          order: 2;
          padding-left: 12px;
        }
        @include respond-to('desktop') {
          width: 57%;
          padding-left: 0;
        }
      }
      &-contact {
        @include respond-to('desktop') {
          display: inline-block;
        }
        p {
          @extend h3;
          margin-top: 59px;
          @include respond-to('tablet_more') {
            margin-top: 13px;
            margin-bottom: 4px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 22px;
            line-height: 27px;
          }
          @include respond-to('desktop') {
            margin-top: 4px;
            margin-bottom: 12px;
          }
        }
        a {
          font-family: $ubuntu-font;
          color: $white;
          font-size: 14px;
          line-height: 2.3em;
          display: inline-block;
          width: 100%;
          @include respond-to('desktop') {
            font-size: 13px;
            line-height: 2.46em;
          }
        }
      }
      &-button {
        @include respond-to('desktop') {
          display: inline-block;
          float: right;
        }
        .button-nav {
          width: 200px;
          margin-top: 16px;
          font-family: $montserrat-font;
          font-size: 12px;
          line-height: 64px;
          @include respond-to('desktop') {
            margin-top: 12px;
          }
        }
      }
      &-producent {
        margin-top: 76px;
        font-size: 14px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.5);
        @include respond-to('desktop') {
          margin-top: 18px;
          font-size: 13px;
          line-height: 18px;
        }
        a {
          color: $white;
          @include respond-to('desktop') {
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
      &-partners {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.5);
        line-height: 3.64em;
        margin-top: 28px;
        flex-wrap: wrap;
        display: flex;
        margin-bottom: 39px;
        align-items: center;
        @include respond-to('tablet_more') {
          flex: initial;
          margin-top: 42px;
          margin-bottom: 0;
        }
        @include respond-to('desktop') {
          border-top: 1px solid rgba(177, 183, 192, 0.21);
          font-size: 13px;
          margin-top: 29px;
          justify-content: flex-end;
        }
        span {
          flex: 1 0 100%;
          @include respond-to('tablet_more') {
            flex: initial;
          }
          @include respond-to('desktop') {
            margin-top: 35px;
          }
        }
        &--img {
          display: flex;
          justify-content: space-between;
          @include respond-to('desktop') {
            margin-top: 35px;
          }
        }
      }
      &-link, &-link--nav-link, &-copywright {
        font-size: 11px;
        color: rgba(255, 255, 255, 0.5);
      }
      &-link {
        display: none;
        margin-top: 57px;
        @include respond-to('tablet_more') {
          display: block;
          margin-top: 90px;
          width: auto;
          max-width: 43%;
          position: absolute;
        }
        @include respond-to('desktop') {
          margin-top: inherit;
          bottom: 74px;
          font-size: 13px;
          line-height: 3.08em;
        }
        &--nav-link {
          line-height: 3.64em;
          display: block;
          //[Front] Nie pomaga na zatrzymanie obniżania napisów dla hover o 1px; align-self: center; transform: translateZ(0);
        }
      }
      &-copywright {
        display: none;
        line-height: 3.64em;
        white-space: nowrap;
        border-top: 1px solid rgba(177, 183, 192, 0.21);
        margin: 0 -7px;
        padding: 11px 7px 0 7px;
        @include respond-to('tablet_more') {
          display: block;
          order: 3;
          margin-top: 31px;
        }
        @include respond-to('desktop') {
          margin: 35px 0 19px 0;
          padding: 11px 0 0 0;
          font-size: 10px;
          line-height: 1.8em;
        }
      }
    }
  }
}
