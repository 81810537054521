.about-project {
}

.container--project {
  @include respond-to('tablet') {
    max-width: 100%;
    padding-right: 47px;
    padding-left: 40px;
  }
  @include respond-to('desktop') {
    //max-width: 100%;
    padding: 0;
  }

}

.module-description {
  @include respond-to('tablet') {
    width: 100%;
  }
  @include respond-to('phone') {
    width: 100%;
  }
  &__h1 {
    margin-top: 138px;
    &--main-topic {
      font-family: $montserrat-font;
      font-size: 64px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.25;
      letter-spacing: -1px;
      color: #000000;

      @include respond-to('tablet') {

        font-family: $montserrat-font;
        font-size: 48px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: -0.77px;
        color: #000000;
      }

      @include respond-to('phone') {
        font-family: $montserrat-font;
        font-size: 40px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: -0.56px;
        color: #000000;
        margin-bottom: 53px;
      }
    }
  }
  &__describe {
    font-family: $ubuntu-font;
    font-size: 22px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: -0.7px;
    color: #000000;

    @include respond-to('tabletandmobile') {
      font-size: 18px;
      font-weight: bold;
      line-height: 1.78;
      letter-spacing: -0.58px;
    }
    &--bold {
      margin-top: 34px;
      font-weight: bold;
      @include respond-to('tablet') {
        margin-top: 60px;
      }
    }
    &--light {
      margin-top: 21px;
      font-weight: normal;
      margin-bottom: 125px;
      @include respond-to('tablet') {
        margin-top: 36px;
        margin-bottom: 121px;
        letter-spacing: -0.58px;
        padding-right: 38px;
      }
      @include respond-to('phone') {
        margin-top: 34px;
        letter-spacing: -0.85px;
        margin-bottom: 78px;
      }
    }
  }
}

.module-box {
  width: 100%;
  margin-bottom: 125px;
  @include respond-to('phone') {
    margin-bottom: 160px;
  }
  @include respond-to('tablet') {
    margin-bottom: 123px;
  }
  &__square {
    width: 40px;
    height: 40px;
    @include respond-to('phone') {
      width: 36px;
      margin-bottom: 34px;
    }
    &--blue {
      background: $blue;
      font-family: $montserrat-font;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      line-height: 40px;
    }

  }
  &__left {
    display: inline-block;
    vertical-align: top;
    width: 42.5%;
    @include respond-to('tabletandmobile') {
      width: 100%;
    }
    &__h2 {
      margin-top: 5px;
      @include respond-to('tabletandmobile') {
        margin-bottom: 40px;
      }
      @include respond-to('tablet_more') {
        margin-top: 8px;
      }
      &--topic {
        font-family: $montserrat-font;
        font-size: 56px;
        color: #000000;

        @include respond-to('tablet') {
          font-size: 48px;
          line-height: 1.17;
          letter-spacing: -0.77px;
          br {
            display: none;
          }
        }
        @include respond-to('phone') {
          font-size: 40px;
        }
      }
    }
  }

  &__right {
    display: inline-block;
    vertical-align: top;
    width: 57%;
    position: relative;
    @include respond-to('phone') {
      width: calc(100% + 30px);
      margin-left: -15px;
    }
    @include respond-to('tablet') {
      width: 100%;
    }

    &__text {
      &--describe {
        font-family: $ubuntu-font;
        font-size: 22px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        color: #000000;
        @include respond-to('phone') {
          padding-right: 15px;
          padding-left: 15px;
        }

        @include respond-to('tabletandmobile') {
          font-size: 18px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.78;
          letter-spacing: normal;
          color: #000000;
        }
        @include respond-to('desktop') {
          padding-top: 30px;
        }
      }
      &__ul {
        list-style: none;
        margin-top: 58px;
        padding: 23px 33px 45px 40px;

        @include respond-to('phone') {
          padding: 20px 15px 72px 15px;
          margin-top: 30px;
        }
        @include respond-to('tablet') {
          padding: 42px 138px 73px 40px;
          margin-top: 20px;
          margin-right: 0;
        }

        &--blue {
          background: $blue;
        }
        &__li {
          margin-top: 24px;
          @include respond-to('phone') {
            width: 100%;
          }
          &--white {
            font-family: $montserrat-font;
            font-size: 22px;
            font-weight: bold;
            color: $white;
            display: flex;
            z-index: 1;
            position: relative;
            @include respond-to('tabletandmobile') {
              font-size: 18px;
            }
          }
        }
      }
    }
    &__arrow {
      width: 39px;
      height: 39px;
      transform: rotate(-45deg);
      background-color: $blue;
      position: absolute;
      bottom: 133px;
      left: calc(50% - 19px);
      @include respond-to('phone') {
        bottom: 195px;
      }
      @include respond-to('tablet') {
        bottom: 157px;
      }
      @include respond-to('desktop') {
        bottom: 112px;
      }
    }

    &__button {
      width: 388px;
      height: 64px;
      margin-left: calc(50% - 194px);
      margin-top: 51px;
      transition: 0.3s;

      @include respond-to('phone') {
        width: 290px;
        margin-left: calc(50% - 145px);
        margin-top: 78px;
      }
      @include respond-to('tablet') {
        margin-top: 97px;
      }
      &--blue {
        background-color: #6f83ff;
        font-family: $montserrat-font;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        text-decoration: none;
        line-height: 67px;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        @include respond-to('phone') {
          margin-bottom: 51px;
        }
        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }
      &:hover {
        background: $blue !important;
      }

    }
  }

}

