.not-precise-modal {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: visibitlity .3s, opacity .3s ease;
  padding: 10px;
  @include respond-to('phone') {
    overflow: scroll;
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &__inner {
    position: relative;
    border-radius: 2px;
    background: #ffffff;
    padding: 0;
    box-shadow: 0 0 30px rgba(51, 57, 75, 0.2);
    @include respond-to('tablet') {
      height: 435px;
      position: absolute;
      top: calc(50vh - 216px);
      left: calc(50% - 384px);
    }
    @include respond-to('desktop') {
      height: 500px;
      position: absolute;
      top: calc(50vh - 250px);
      left: calc(50% - 420px);
      max-width: 840px;
    }
  }

  &__close {
    width: 64px;
    height: 64px;
    position: absolute;
    z-index: 10000;
    top: 30px;
    right: 30px;
    background: rgba(177, 183, 192, 0);
    cursor: pointer;
    transition: background-color .3s ease;
    @include respond-to('tablet') {
      top: 20px;
      right: 20px;
    }
    @include respond-to('phone') {
      top: 12px;
      right: 12px;
    }

    &:hover {
      background: rgba(177, 183, 192, 0.1);
    }

    &:before {
      content: ' ';
      background: #b1b7c0;
      height: 2px;
      width: 40px;
      position: absolute;
      left: 12px;
      top: 32px;
      transform: rotate(45deg);
      transform-origin: center;
    }

    &:after {
      content: ' ';
      background: #b1b7c0;
      height: 2px;
      width: 40px;
      position: absolute;
      left: 12px;
      top: 32px;
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }

  &__container {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
  }

  &__illustration {
    background: url('../../ncimp/img/not-precise-modal-img.png') no-repeat center center;
    background-size: contain;
    height: 335px;
    @include respond-to('tablet_more') {
      min-width: 300px;
      max-width: 300px;
    }

    @include respond-to('desktop') {
      min-width: 375px;
      max-width: 375px;
    }
  }

  &__content {
    margin: 90px auto 45px;
    padding: 0 20px;
    height: calc(100vh - 155px);
    @include respond-to('tablet_more') {
      height: auto;
      display: flex;
      margin-bottom: 0;

      .text {
        padding: 0 24px 24px 48px;
        align-self: flex-end;
        line-height: normal;
      }
    }

    @include respond-to('desktop') {
      .text {
        padding-bottom: 0;
      }
    }
  }
}