.navbar {
  background: $dark-grey !important;
  color: $white;
  font-family: $montserrat-font;
  padding: 8px 16px;
  height: 64px;
  transition: 0.5s;
  z-index: 1021;
  position: fixed;
  @include respond-to('tablet_more') {
    padding-right: 21px;
  }
  @include respond-to('netbook') {
    padding-left: 40px;
  }
  @include respond-to('hd') {
    height: 88px;
    padding-left: 88px;
  }

  + * {
    margin-top: 64px;
    @include respond-to('hd') {
      margin-top: 88px;
    }
  }

  .skip-links {
    z-index: 5000;
    position: absolute;
    top: 0;
    left: 0;

    a {
      position: absolute;
      left: -9000em;
      display: block;
      white-space: nowrap;
      padding: 1em 2em;
      background-color: black;
      border: 2px solid white;
      color: white;
      font-weight: bold;

      &:focus {
        left: 0;
      }
    }
  }

  .navbar-brand {
    text-transform: uppercase;
    padding: 0;
    font-size: 16px;
    margin-top: -11px;
    line-height: 16px;
    z-index: 9;
    @include respond-to('tablet_more') {
      max-width: 50%;
    }

    img {
      width: 22px;
      vertical-align: bottom;
      margin-right: 13px;
      @include respond-to('tablet_more') {
        margin-right: 18px;
      }
      @include respond-to('hd') {
        margin-right: 20px;
        width: 25px;
      }
    }

    > span {
      font-weight: bold;
      display: inline-block;
      white-space: pre-line;
      vertical-align: super;

      span {
        opacity: 0.5;
      }
    }
  }

  .navbar-toggler {
    padding: 0;
    border: 0 solid;
    border-radius: 0;
    background: transparent !important;
    width: auto;
    @include media-breakpoint-down(md) {
      display: inline-block;
    }

    &:hover, &:focus {
      background: transparent !important;
    }

    .icon-bar {
      transition: 0.2s;
      display: block;
      width: 32px;
      height: 2px;
      border-radius: 0;
      background: $white;

      + .icon-bar {
        margin-top: 9px;

        &:nth-child(2) {
          width: 75%;
          margin-left: 25%;
        }
      }
    }

    &[aria-expanded="true"] {
      .icon-bar {
        opacity: 0.5;
        margin-top: 0;

        &:nth-child(1) {
          transform: rotate(45deg);
          width: 40px;
          margin-left: -4px;
        }

        &:nth-child(2) {
          transform: rotateY(90deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
          margin-top: -4px;
          width: 40px;
          margin-left: -4px;
        }
      }
    }
  }

  .navbar-collapse {
    position: fixed;
    left: 0;
    top: 63px;
    width: 100%;
    z-index: 1;
    @include respond-to('netbook') {
      width: auto;
      height: auto;
      position: static;
    }

    &:before {
      content: "";
      width: 100%;
      height: calc(100vh - 22px);
      position: absolute;
      background: $dark-grey;
      @include respond-to('netbook') {
        display: none;
      }
    }

    ul.navbar-nav {
      z-index: 1;
      margin: 2px 16px 0 16px;
      border-top: 2px solid rgba(177, 183, 192, 0.3);
      border-bottom: 2px solid rgba(177, 183, 192, 0.3);
      position: relative;
      width: calc(100% - 32px);
      padding: 4px 0;
      height: calc(100% - 68px);
      @include respond-to('tablet_more') {
        margin: 4px 32px 0 32px;
        width: calc(100% - 64px);
        padding-bottom: 130px;
      }
      @include respond-to('tablet') {
        padding-bottom: 10px;
      }
      @include respond-to('netbook') {
        border: 0 solid;
        width: auto;
        margin: 0 auto;
      }

      li {
        z-index: 1;
        font-size: 14px;
        padding: 0 24px;
        font-weight: bold;
        color: $white;
        height: 48px;
        opacity: 1;
        transition: height 0.25s ease-in, opacity 0.25s ease-in;
        @include respond-to('netbook') {
          padding: 0 10px;
        }

        @include respond-to('hd') {
          padding: 0 16px;
        }

        &:first-child {
          @include respond-to('tablet_more') {
            border-bottom: 0 solid;
          }
        }

        &.report {
          @include respond-to(netbook) {
            order: 4;
          }
          @include respond-to(desktop) {
            margin-left: 85px;
          }
        }

        &.nav-item.dropdown {
          order: 3;

          &.show {
            z-index: 2;
            overflow: hidden;
            @include respond-to('netbook') {
              padding: 114px 42px 0;

              .menu-nav__close {
                cursor: pointer;
                display: inline-block;
                position: fixed;
                top: 10px;
                right: 40px;
                transition: background 0.2s;

                background: url('../ncimp/img/close.png') center no-repeat;
                background-size: 30px 30px;
                height: 48px;
                width: 48px;

                &:hover {
                  background-color: rgba(177, 183, 192, 0.1);
                }
              }

              > .nav-link.dropdown-toggle {
                display: none;
              }

              .button-nav {
                width: 200px;
                left: 50%;
                transform: translateX(-50%);
                bottom: 48px;
              }
            }

            @include respond-to('hd') {
              padding-top: 138px;
            }

            ~ .nav-item {
              height: 0;
              opacity: 0;
              transition: height 0.25s ease-in, opacity 0.25s ease-in;

              a {
                display: none;
              }

              &.city-button a {
                display: inline-block;
              }
            }
          }

          &#navbar-city a[role="button"]:hover {
            background: transparent !important;
          }
        }

        a {
          padding: 0;
          font-size: 14px;
          line-height: 48px;
          color: $white;
          background: transparent;
          display: inline-block;
          font-weight: bold;
          position: relative;
          width: 100%;
          text-align: left;
          height: auto;

          i {
            display: inline-block;
            font-size: 10px;
            font-style: normal;
            background-color: $blue;
            line-height: 20px;
            padding: 0 5px;
            vertical-align: middle;
            margin-left: 10px;
            text-transform: uppercase;
          }

          &.dropdown-toggle {
            background: transparent;
            padding-right: 20px;

            &:hover {
              background: transparent !important;
            }

            &:after {
              display: none;
            }

            span {
              position: relative;
              text-transform: none;

              &:before, &:after {
                display: inline-block;
                content: "";
                position: absolute;
                bottom: 10px;
                border-top: 2px solid #fff;
                border-right: 0 solid;
                border-bottom: 0 solid;
                border-left: 0 solid;
                width: 5px;
                height: 2px;
                margin-left: 0;
                vertical-align: 0;
                transition: 0.3s;
              }

              &:before {
                transform: rotateZ(45deg) translateX(4px);
                right: -7px;
              }

              &:after {
                transform: rotateZ(-45deg) translateX(8.5px) translateY(12.5px);
                right: 2px;
              }
            }

            &[aria-expanded="true"] span {
              &:before {
                right: -10px;
              }

              &:after {
                right: 5px;
              }
            }
          }

          @include respond-to('hd') {
            font-size: 16px;
          }
        }

        .dropdown-menu {
          margin: 0;
          padding: 0;
          border-radius: 0;
          border: 0;
          background: transparent;
          height: 0;
          opacity: 0;
          transition: height 0.25s ease-in, opacity 0.25s ease-in;
          transition-delay: 0.25s;

          &.show {
            opacity: 1 !important;
            top: 0;
            height: calc(100% - 180px);
            position: relative;
            @include respond-to('phone') {
              height: 250px;
              height: -moz-calc(100% - 180px);
            }

            .nav-list-city {
              overflow-y: scroll;
              overflow-x: hidden;
              max-height: 100%;
              font-size: 16px;
              line-height: 48px;
              font-family: $ubuntu-font;
              border-top: 2px solid rgba(177, 183, 192, 0.3);
              padding: 22px 0;
              margin: 0 -24px;
              font-weight: 400;
              @include respond-to('desktop') {
                height: calc(100% - 196px);
              }
              @include respond-to('tablet') {
                max-height: 55%;
                min-height: 40vh;
              }

              &--item, &--failure {
                margin: 0;
                color: $white;
              }

              &--item {
                background: $dark-grey;
                position: relative;
                @include respond-to('tablet_more') {
                  width: 33%;
                  display: inline-block;
                  float: left;
                  &:before {
                    content: "";
                    border-top: 2px solid rgba(177, 183, 192, 0.12);
                    display: inline-block;
                    width: 400%;
                    position: absolute;
                    height: 100%;
                    background: $dark-grey;
                  }
                  &:nth-child(1):before,
                  &:nth-child(2):before,
                  &:nth-child(3):before {
                    display: none;
                  }
                }
                @include respond-to('netbook') {
                  width: 25%;
                  display: inline-block;
                  &:nth-child(1):before,
                  &:nth-child(2):before,
                  &:nth-child(3):before,
                  &:nth-child(4):before {
                    display: none;
                  }
                  a {
                    padding: 0 62px;
                    font-weight: 400;
                  }
                }

                a {
                  padding: 0 24px;
                  @include respond-to('desktop') {
                    padding: 0 62px;
                  }
                }
              }

              &--failure {
                position: absolute;
                padding: 0 24px;
                width: 100%;
                text-align: center;
                margin-left: 24px;
              }

              &--href {
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                vertical-align: middle;
              }
            }
          }

          &--nav-city-search {
            .dropdown-menu--input {
              background: transparent;
              color: $white;
              outline-offset: 0;
              border: 0 solid;
              font-size: 22px;
              line-height: 27px;
              width: 100%;
              font-weight: bold;
              text-align: center;
              @include respond-to('mobile') {
                margin-top: 20px;
                margin-bottom: 10px;
              }

              &::placeholder {
                color: rgba(255, 255, 255, 0.5);
                text-align: left;
                @include respond-to('tablet_more') {
                  text-align: center;
                  color: rgba(255, 255, 255, 0.2);
                }
              }

              @include respond-to('netbook') {
                margin-top: 25px;
                font-size: 64px;
                font-weight: bold;
                line-height: 80px;
                letter-spacing: 2.8px;
              }
            }

            p {
              color: $white;
              font-size: 16px;
              line-height: 16px;
              margin: 61px 0 31px 0;
              text-align: left;

              @include respond-to('phone') {
                margin-top: 31px;
              }

              @include respond-to('tablet') {
                margin-top: 41px;
                margin-bottom: 35px;
              }

              @include respond-to('desktop') {
                margin-top: 41px;
                margin-bottom: 45px;
                text-align: center;
              }
            }
          }
        }
      }

      &.main-navbar {
        @include respond-to('netbook') {
          padding-bottom: 0;
          position: static;
        }

        .dropdown.show {
          position: fixed;
          width: 100%;
          height: calc(100% - 129px);
          top: 67px;
          left: 0;
          background: $dark-grey;
          padding: 0px 40px;
          @include respond-to('tablet_more') {
            height: calc(100% - 177px);
          }
          @include respond-to('netbook') {
            height: 100%;
            top: 0;
          }
          @include respond-to('netbook') {
            ~ .city-button {
              position: fixed !important;
              z-index: 2;
              top: inherit !important;
              right: inherit;
              bottom: 72px;
              margin-left: -200px;
            }
          }
        }
      }
    }
  }

  .contrast-button {
    position: relative;
    right: 125px;
    color: $white;
  }

  .city-button {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 0;
    height: 64px !important;
    opacity: 1 !important;
    margin-right: 0;
    @include respond-to('tablet_more') {
      width: 200px;
      left: 50%;
      margin-left: -100px;
      bottom: 48px;
    }
    @include respond-to('netbook') {
      right: 0;
      top: 0;
      left: inherit;
      margin-right: -30px;
      position: relative;
    }
    @include respond-to('hd') {
      height: 88px !important;
      .button-nav {
        height: 88px !important;
        line-height: 88px !important;
      }
    }

    &.showit {
      @include respond-to('tablet') {
        display: block;
        right: inherit;
        margin-top: calc(100vh - 130px);
        left: 50%;
      }
      @include respond-to('desktop') {
        position: absolute;
        right: inherit;
        margin-top: calc(100vh - 130px);
        left: 50%;
        display: block;
      }
    }

    .button-nav {
      position: relative;
      width: 100%;
      z-index: 1;
      background: #6f83ff;
      line-height: 64px;
      text-align: center;
    }
  }
}
