.budgets {
  .title-container {
    margin-bottom: 24px;
  }

  .list-cities {
    padding: 0;
    margin-bottom: 80px;
    @include respond-to(tablet) {
      display: block !important;
    }
    @include respond-to(tablet_more) {
      margin-bottom: 120px;
    }

    &.expanded {
      margin-bottom: 0;

      .box-city > div:nth-child(n + 4) {
        display: block;
      }

      .button-budget {
        display: none;
      }
    }

    .box-city {
      margin-top: 0;
      margin-bottom: 25px;
      @include respond-to(tablet_more) {
        margin-bottom: 80px;
      }

      > div:nth-child(n + 4) {
        display: none;
      }

      .box-cities {
        margin-top: 0;
        margin-bottom: 25px;

        &--caption {
          align-items: center;
          min-height: auto;
          padding: 23px 16px;
        }

        &--link {
          font-size: 16px;
          font-weight: bold;
          line-height: normal;
          color: #b1b7c0;
          margin-bottom: 0;
          padding-top: 0;
        }

        &--city-year {
          .box-cities--link {
            font-size: 32px;
            color: #000;
          }
        }
      }
    }

    .button-budget {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
